import React from 'react';
import { MainLoader } from '@/ui/Loading';
import { MapContainer } from './MapContainer';
import { Layers } from './Layers';
import { TileLayer } from './Layers/TileLayer';
import { VectorLayer } from './Layers/VectorLayer';
import { Controls } from './Controls';
import { Profiles } from './Controls/Profiles';
import { Fullscreen } from './Controls/Fullscreen';
import { Zoom } from './Controls/Zoom';
import { LanguageSwitch } from './Controls/LanguageSwitch';
import { Search } from './Controls/Search';
import { AsideInformation } from './Controls/AsideInformation';
import { Categories } from './Controls/Categories';
import { Routing } from './Controls/Routing';
import { Interactions } from './Interactions';
import { SelectInteraction } from './Interactions/SelectInteraction';
import { SrOnly } from '@/ui/SrOnly';
import { useTranslation } from 'react-i18next';

import { useConfigurationQuery, useGeoCategoriesQuery, useGeoObjectsQuery, useBuildingsQuery } from './queries';
import { RouteProvider } from './Context/useRoute';

export const Map = (): JSX.Element => {
  const {t} = useTranslation();
  const { data: geoObjects, isLoading: loadingGeoObjects } = useGeoObjectsQuery();
  const { data: geoCategories, isLoading: loadingGeoCategories } = useGeoCategoriesQuery();
  const { data: configuration, isLoading: loadingConfiguration } = useConfigurationQuery();
  const { data: buildings, isLoading: loadingBuildings } = useBuildingsQuery();

  return (
    <React.Fragment>
      <SrOnly><h1>{t('labels.appTitle')}</h1></SrOnly>
      <MainLoader loading={loadingGeoObjects || loadingGeoCategories || loadingConfiguration || loadingBuildings}>
        {geoObjects && geoCategories && configuration && buildings && (
          <RouteProvider>
            <MapContainer geoObjects={geoObjects} geoCategories={geoCategories} configuration={configuration} buildings={buildings}>
              <Layers>
                <TileLayer zIndex={0} />
                <VectorLayer zIndex={1} />
              </Layers>
              <Controls>
                <Profiles />
                <Fullscreen />
                <Zoom />
                <LanguageSwitch />
                <Search />
                <AsideInformation />
                <Categories />
                <Routing />
              </Controls>
              <Interactions>
                <SelectInteraction />
              </Interactions>
            </MapContainer>
          </RouteProvider>
        )}
      </MainLoader>
    </React.Fragment>
  );
};
