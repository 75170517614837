import { useCallback, useEffect, useMemo } from 'react';
import { ObjectEvent } from 'ol/Object';

import { useMapDispatchContext, useMapStateContext } from '../../Context/useMapContext';
import { mapConfig } from '../../config';

import { Text } from '@/ui/Text';

import { ZoomButton, ZoomContainer, ZoomInfo } from './styles';
import { useTranslation } from 'react-i18next';

export const Zoom = (): JSX.Element => {
  const {t} = useTranslation();
  const { map, configuration, zoom } = useMapStateContext();
  const { handleZoom } = useMapDispatchContext();

  const minZoom = useMemo(() => configuration?.minZoom ?? mapConfig.minZoom, [configuration]);
  const delta = useMemo(() => (minZoom * 5) / 100.0, [minZoom]);
  const getRoundedZoom = useCallback((zoom: number) => Math.round((zoom * 100.0) / minZoom), [minZoom]);

  const handleMouseWheel = useCallback(
    (e: ObjectEvent) => {
      handleZoom(e.target.getZoom(), true);
    },
    [handleZoom],
  );

  useEffect(() => {
    const mapView = map?.getView();

    if (!mapView) {
      return;
    }

    mapView.on('change:resolution', handleMouseWheel);

    return () => {
      mapView.un('change:resolution', handleMouseWheel);
    };
  }, [handleMouseWheel, map]);

  return (
    <ZoomContainer>
      <ZoomButton onClick={() => handleZoom(-delta)}>
        <Text srOnly={true}>{t('labels.mapZoomOut')}</Text>
        <Text weight={400} size={14} palette={{ light: 0 }} aria-hidden="true">
          -
        </Text>
      </ZoomButton>
      <ZoomInfo>
        <Text srOnly={true}>{t('labels.currentZoomLevel')}: </Text>
        <Text weight={400} size={14} palette={{ primary: 100 }}>
          {getRoundedZoom(zoom)}%
        </Text>
      </ZoomInfo>
      <ZoomButton onClick={() => handleZoom(delta)}>
        <Text srOnly={true}>{t('labels.mapZoomIn')}</Text>
        <Text weight={400} size={14} palette={{ light: 0 }} aria-hidden="true">
          +
        </Text>
      </ZoomButton>
    </ZoomContainer>
  );
};
