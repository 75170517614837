import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMapStateContext } from '../../Context/useMapContext';

import { Text } from '@/ui/Text';
import { Icon } from '@/ui/Icon';
import { Box } from '@/ui/Box';

import pointIcon from '@/assets/icons/point.svg';
import routeDarkIcon from '@/assets/icons/routeDark.svg';
import shareDarkIcon from '@/assets/icons/shareDark.svg';
import photoIcon from '@/assets/icons/photo.svg';
import arrowUpIcon from '@/assets/icons/arrowUpLight.svg';
import {
  ActionButton,
  AsideInformationContainer,
  BuildingPhoto,
  Category,
  Location,
  MainInformation,
  TitleContainer,
  CollapseButton,
  DescriptionContainer,
} from './styles';
import { SrOnly } from '@/ui/SrOnly';
import { useRouteState } from '../../Context/useRoute';
import { useRouteDispatch } from '../../Context/useRoute';
import { ShareModal } from '../ShareModal';
import { IGeoObject } from '../../types';

export const AsideInformation = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedObject: selectedObjectFromContext, geoCategories, geoObjects, selectedCustomMapObject } = useMapStateContext();
  const selectedObject = geoObjects.find((geoObject) => geoObject.id === selectedObjectFromContext?.id);
  const {openRoutePanel} = useRouteState();
  const {savePoint, setOpenRoutePanel} = useRouteDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [collapse, setCollapse] = React.useState(true);

  const handlePlanRoute = React.useCallback(() => {
    if (selectedObject) {
      savePoint('destinationPoint', selectedObject);
      setOpenRoutePanel(true);
    } else if (selectedCustomMapObject) {
      savePoint('destinationPoint', new IGeoObject(selectedCustomMapObject, t(`map.customMapObjectNames.${selectedCustomMapObject.keyName}`)));
      setOpenRoutePanel(true);
    }
  }, [savePoint, selectedCustomMapObject, selectedObject, setOpenRoutePanel, t]);

  React.useEffect(() => {
    setCollapse(true);
  }, [selectedObject]);

  return (
    <>
      {(selectedObject || selectedCustomMapObject) && !openRoutePanel && (
        <>
          <AsideInformationContainer>
            <aside>
              <MainInformation>
                <TitleContainer>
                  <SrOnly><h2>{selectedObject ? selectedObject.properties.name : selectedCustomMapObject ? t(`map.customMapObjectNames.${selectedCustomMapObject.keyName}`) : ''}</h2></SrOnly>
                  <Category>
                    {selectedObject?.properties.typeKey === 'building' && selectedObject.properties.image && (
                      <Icon src={photoIcon} size={[16, 16]} />
                    )}
                    <Text weight={400} size={14} palette={{ light: 0 }}>
                      {geoCategories.find(geoCategory => geoCategory.id === selectedObject?.properties.categoryGeo)?.name ?? ''}
                    </Text>
                  </Category>
                  <Text weight={700} size={20} palette={{ light: 0 }} aria-hidden="true">
                    {selectedObject ? selectedObject.properties.name : selectedCustomMapObject ? t(`map.customMapObjectNames.${selectedCustomMapObject.keyName}`) : ''}
                  </Text>
                  {selectedObject?.properties.location && (
                    <Location>
                      <Icon src={pointIcon} size={[16, 16]} />
                      <Text weight={400} size={14} palette={{ light: 0 }}>
                        {selectedObject.properties.location}
                      </Text>
                    </Location>
                  )}
                </TitleContainer>
                {
                  selectedObject &&
                  <ActionButton onClick={() => setModalOpen(true)}>
                    <Text srOnly={true}>{t('labels.share')} {selectedObject?.properties.name}</Text>
                    <Icon src={shareDarkIcon} size={[26, 22]} />
                  </ActionButton>
                }
                <ActionButton onClick={handlePlanRoute}>
                  <Text srOnly={true}>{t('labels.planRouteTo')} {selectedObject ? selectedObject.properties.name : selectedCustomMapObject ? t(`map.customMapObjectNames.${selectedCustomMapObject.keyName}`) : ''}</Text>
                  <Icon src={routeDarkIcon} size={[26, 22]} />
                </ActionButton>
              </MainInformation>
              {selectedObject?.properties.description && (
                <DescriptionContainer collapse={collapse}>
                  <Box mt={24}>
                    <h3>
                      <Text weight={700} size={13} palette={{ light: 0 }}>
                        {t('map.controls.objectInformation')}
                      </Text>
                    </h3>

                    <p>
                      <Text weight={400} size={13} palette={{ light: 0 }}>
                        {selectedObject.properties.description}
                      </Text>
                    </p>
                  </Box>
                </DescriptionContainer>
              )}
              {selectedObject?.properties.typeKey === 'building' && selectedObject.properties.image && (
                <DescriptionContainer collapse={collapse}>
                  <Box mt={12}>
                    <BuildingPhoto src={selectedObject.properties.image} alt="" />
                  </Box>
                </DescriptionContainer>
              )}
            </aside>
            {(selectedObject?.properties.description || (selectedObject?.properties.typeKey === 'building' && selectedObject.properties.image)) && (
              <CollapseButton onClick={() => setCollapse(!collapse)} collapse={collapse}>
                <Icon src={arrowUpIcon} size={[16, 16]} />
              </CollapseButton>
            )}
          </AsideInformationContainer>
          {
            selectedObject &&
            <ShareModal
              open={modalOpen}
              setClose={() => setModalOpen(false)}
              name={selectedObject.properties.name}
              sharedLink={`${window.location.origin}/?geo=${selectedObject.id}`} />
          }
        </>
      )}
    </>
  );
};
