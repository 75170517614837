import styled from 'styled-components';
import { breakpoints } from '@/settings/globalStyles/breakpoints';

export const ModalContentContainer = styled.div`
  padding: 40px;

  @media (max-width: ${breakpoints.md}) {
    padding: 20px;
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${({ theme }) => theme.palette.light[0]};
  border: none;
  padding: 0;
  cursor: pointer;

  @media (max-width: ${breakpoints.md}) {
    top: 10px;
    right: 10px;
  }
`;

export const ModalInputContainer = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

export const ModalInput = styled.input`
  height: 35px;
  padding: 0 15px;
  margin-right: 10px;
  flex-grow: 1;
  border: 2px solid ${({theme}) => theme.palette.primary['100']};
  border-radius: ${({ theme }) => theme.borderRadius[10]};
  color: ${({theme}) => theme.palette.primary['100']};
  font-size: 14px;
  font-weight: 400;

  @media (max-width: ${breakpoints.md}) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.palette.primary['100']};
  border: none;
  padding: 0 15px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius[10]};

  @media (max-width: ${breakpoints.md}) {
    height: 35px;
  }
`;

export const modalCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 999,
  },
  content: {
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    maxHeight: 250,
    borderRadius: 12,
    border: 'none',
    backgroundColor: '#FFFFFF',
    padding: 0,
  },
};
