import { Icon } from '@/ui/Icon';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { breakpoints } from '@/settings/globalStyles/breakpoints';

export const RoutingContainer = styled.div<{collapse: boolean}>`
  z-index: 20;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 460px;
  max-height: 75vh;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  background-color: ${({ theme }) => theme.palette.light['0']};
  padding: 1.6rem;
  border-radius: ${({ theme }) => theme.borderRadius[12]};
  box-sizing: border-box;
  overflow: auto;

  @media (max-width: ${breakpoints.md}) {
    padding: 1.6rem 1.6rem 3rem 1.6rem;
    max-height: 75vh;
    width: auto;
    right: 15px;
    height: ${({collapse}) => collapse ? '65px' : 'auto'};;
    overflow: auto;
  }
`;

export const RoutingContainerItem = styled.div<{collapse: boolean}>`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoints.md}) {
    display: ${({collapse}) => collapse ? 'none' : 'flex'};
  }
`;

export const TitleContainer = styled.h2`
  flex-grow: 1;
  text-align: center;
`;

export const ActionButton = styled.button<{ mode: 'primary' | 'secondary' | 'flat' }>`
  width: ${({ mode }) => (mode === 'flat' ? `35px` : `42px`)};
  height: ${({ mode }) => (mode === 'flat' ? `35px` : `42px`)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, mode }) =>
    mode === 'primary' ? theme.palette.primary[100] : mode === 'secondary' ? theme.palette.light[0] : 'transparent'};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  border: ${({ theme, mode }) => (mode === 'flat' ? 'none' : `1px solid ${theme.palette.primary[100]}`)};
  padding: ${({ mode }) => (mode === 'flat' ? 0 : `0.4rem`)};
  cursor: pointer;
`;

export const TrashButtonContainer = styled.div`
  position: absolute;
  top: 2px;
  right: -45px;

  @media (max-width: ${breakpoints.md}) {
    right: -35px;
  }
`;

export const ButtonContainer = styled.div<{collapse: boolean}>`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    display: ${({collapse}) => collapse ? 'none' : 'flex'};
  }
`;

export const RoutingInput = styled.input`
  height: 35px;
  padding: 0 1rem 0 4rem;

  width: 100%;
  background-color: ${({ theme }) => theme.palette.light['0']};
  border-radius: ${({ theme }) => theme.borderRadius[12]};
  border: 2px solid ${({ theme }) => theme.palette.primary[100]};
`;

export const RoutingInputIcon = styled(Icon)`
  position: absolute;
  padding-left: 1rem;
`;

export const RoutingInputContainer = styled.div`
  display: inherit;
  align-items: center;
`;

export const RoutingWithResults = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputsContainer = styled.div`
  display: inherit;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  margin-right: 80px;
  margin-left: 40px;

  @media (max-width: ${breakpoints.md}) {
    margin-right: 40px;
    margin-left: 0;
  }
`;

export const InfiniteScrollTrigger = styled.div`
  height: 44px;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.light[0]};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchResultsContainer = styled(SimpleBar)`
  top: 4rem;
  z-index: 25;
  max-height: 60vh;
  margin: 0.5rem 0 0 0;
  padding: 0;

  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.light[0]};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius[12]};
  filter: drop-shadow(0px 0px 2px black);

  @media (max-width: ${breakpoints.md}) {
    max-height: 20vh;
  }
`;

export const SearchResultsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const SearchResult = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.palette.light[75]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.light[50]};
    border-radius: ${({ theme }) => theme.borderRadius[8]};
  }

  &:focus-visible {
    outline-offset: -2px;
  }
`;

export const SearchResultIcon = styled(Icon)`
  padding: 0.6rem;
`;

export const CollapseButton = styled.button<{collapse: boolean}>`
  display: none;

  @media (max-width: ${breakpoints.md}) {
    position: ${({collapse}) => collapse ? 'absolute' : 'static'};
    right: 15px;
    bottom: 15px;
    left: 15px;
    display: block;
    transform: ${({collapse}) => collapse ? 'rotate(180deg)' : 'rotate(0)'};
    border: none;
    background-color: transparent;
  }
`;

export const RouteDetailsContainer = styled.div<{collapse: boolean}>`
  @media (max-width: ${breakpoints.md}) {
    display: ${({collapse}) => collapse ? 'none' : 'block'};
  }
`;

export const RouteDetailsTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 10px;
  flex-grow: 1;
  text-align: center;
`;

export const RouteDirectionList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const RouteDirectionIcon = styled(Icon)`
  margin-right: 20px;
`;

export const RouteDirectionItem = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.light[100]};
  background-color: transparent;
  cursor: pointer;
`;

export const ModalContentContainer = styled.div`
  padding: 40px;

  @media (max-width: ${breakpoints.md}) {
    padding: 20px;
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${({ theme }) => theme.palette.light[0]};
  border: none;
  padding: 0;
  cursor: pointer;

  @media (max-width: ${breakpoints.md}) {
    top: 10px;
    right: 10px;
  }
`;

export const ModalButton = styled.button`
  position: relative;
  padding: 15px 15px 15px 40px;
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 1px solid ${({theme}) => theme.palette.light['100']};
  background-color: transparent;
  cursor: pointer;
`;

export const ModalButtonIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
`;

export const modalCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 999,
  },
  content: {
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    height: 'fit-content',
    maxHeight: 350,
    borderRadius: 12,
    border: 'none',
    backgroundColor: '#FFFFFF',
    padding: 0,
  },
};
