import { useContext } from 'react';

import { MapStateContext, MapDispatchContext } from './MapContext';

export const useMapStateContext = () => {
  const mapStateContext = useContext(MapStateContext);

  if (!mapStateContext) {
    throw new Error('Component beyond MapStateContext');
  }

  return mapStateContext;
};

export const useMapDispatchContext = () => {
  const mapDispatchContext = useContext(MapDispatchContext);

  if (!mapDispatchContext) {
    throw new Error('Component beyond MapDispatchContext');
  }

  return mapDispatchContext;
};
