import { CSSProperties } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

interface TextProps {
  weight?: keyof DefaultTheme['typography']['fontWeight'];
  size?: keyof DefaultTheme['typography']['size'];
  palette?: {
    [color in keyof DefaultTheme['palette']]: {
      [value in color]: keyof DefaultTheme['palette'][color];
    };
  }[keyof DefaultTheme['palette']];
  decoration?: CSSProperties['textDecoration'];
  transform?: CSSProperties['textTransform'];
  srOnly?: boolean;
}

export const Text = styled.span<TextProps>`
  font-family: ${({ theme }) => theme.typography.fontFamily};

  ${({ theme, weight }) =>
    weight &&
    css`
      font-weight: ${theme.typography.fontWeight[weight]};
    `}
  ${({ theme, size }) =>
    size &&
    css`
      font-size: ${theme.typography.size[size]};
    `}
  ${({ theme, palette }) => {
    if (!palette) {
      return undefined;
    }

    const colorName = Object.keys(palette)[0] as keyof DefaultTheme['palette'];
    const colorValue = Object.values(palette)[0] as unknown as keyof DefaultTheme['palette'][typeof colorName];

    return css`
      color: ${theme.palette[colorName][colorValue]};
      text-decoration-color: ${theme.palette[colorName][colorValue]};
    `;
  }}
  ${({ decoration }) =>
    decoration &&
    css`
      text-decoration: ${decoration};
    `}
  ${({ transform }) =>
    transform &&
    css`
      text-transform: ${transform};
    `}
  ${({ srOnly }) =>
    srOnly &&
    css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
    `}
`;
