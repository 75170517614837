import React from 'react';
import { IGeoObject, RoutePoint, RouteNode, MappedRouteAccessibility, mappedRouteAccessibility } from '../types';
import { mapService } from '../service';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

type PointType = 'startingPoint' | 'destinationPoint' | 'aPoint' | 'bPoint';
type Point = IGeoObject | undefined;

interface StateContext {
  startingPoint: Point;
  destinationPoint: Point;
  aPoint: Point;
  aPointVisible: boolean;
  bPoint: Point;
  bPointVisible: boolean;
  route: RouteNode[] | undefined;
  openRoutePanel: boolean;
  disabilityFriendly: keyof MappedRouteAccessibility;
  pointFromMap: Point;
}

interface DispatchContext {
  savePoint: (pointType: PointType, point: Point) => void;
  setOpenRoutePanel: (isOpen: boolean) => void;
  setDisabilityFriendly: (disabilityFriendly: keyof MappedRouteAccessibility) => void;
  setWaypointVisibility: (waypoint: Extract<PointType, 'aPoint' | 'bPoint'>, visible: boolean) => void;
  setPointFromMap: (point: Point) => void;
}

const initialStateContext: StateContext = {
  startingPoint: undefined,
  destinationPoint: undefined,
  aPoint: undefined,
  aPointVisible: false,
  bPoint: undefined,
  bPointVisible: false,
  route: undefined,
  openRoutePanel: false,
  disabilityFriendly: 1,
  pointFromMap: undefined,
};

const RouteStateContext = React.createContext<StateContext>(initialStateContext);
const RouteDispatchContext = React.createContext<DispatchContext | undefined>(undefined);

export const RouteProvider = React.memo(({children}: {children: React.ReactNode}) => {
  const {t} = useTranslation();
  const [startingPoint, setStartingPoint] = React.useState<Point>();
  const [destinationPoint, setDestinationPoint] = React.useState<Point>();
  const [aPoint, setAPoint] = React.useState<Point>();
  const [aPointVisible, setAPointVisible] = React.useState(initialStateContext.aPointVisible);
  const [bPoint, setBPoint] = React.useState<Point>();
  const [bPointVisible, setBPointVisible] = React.useState(initialStateContext.bPointVisible);
  const [route, setRoute] = React.useState<RouteNode[]>();
  const [openRoutePanel, setOpenPanel] = React.useState(initialStateContext.openRoutePanel);
  const [disabilityFriendly, setRouteDisabilityFriendly] = React.useState(initialStateContext.disabilityFriendly);
  const [pointFromMap, savePointFromMap] = React.useState(initialStateContext.pointFromMap);

  const stateValue = React.useMemo(() => (
    {startingPoint, destinationPoint, aPoint, aPointVisible, bPoint, bPointVisible, route, openRoutePanel, disabilityFriendly, pointFromMap}),
    [startingPoint, destinationPoint, aPoint, aPointVisible, bPoint, bPointVisible, route, openRoutePanel, disabilityFriendly, pointFromMap]
  );

  React.useEffect(() => {
    const intermediateNodes: RoutePoint[] = [];
    if (aPoint && bPoint) {
      intermediateNodes.push(new RoutePoint(aPoint));
      intermediateNodes.push(new RoutePoint(bPoint));
    } else if (aPoint || bPoint) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      intermediateNodes.push(new RoutePoint(aPoint ? aPoint : bPoint!));
    }

    if (startingPoint && destinationPoint) {
      mapService.getMapRoute({
        nodeList: [new RoutePoint(startingPoint), new RoutePoint(destinationPoint)],
        ...((intermediateNodes.length) && {
          intermediateNodes: intermediateNodes as [RoutePoint],
        }),
        disabilityFriendly: mappedRouteAccessibility[disabilityFriendly],        
      })
        .then((routeData) => {
          setRoute(routeData.response.route);
        })
        .catch(() => {
          toast.error(t('route.otherErrorFromRouter'));
          setRoute(undefined);
        });
    } else {
      setRoute(undefined);
    }
  }, [startingPoint, destinationPoint, aPoint, bPoint, disabilityFriendly, t]);

  const savePoint = React.useCallback((pointType: PointType, point: Point) => {
    if (pointType === 'startingPoint') {
      setStartingPoint(point);
    } else if (pointType === 'destinationPoint') {
      setDestinationPoint(point);
    } else if (pointType === 'aPoint') {
      setAPoint(point);
    } else if (pointType === 'bPoint') {
      setBPoint(point);
    }
  }, []);

  const setOpenRoutePanel = React.useCallback((isOpen: boolean) => {
    setOpenPanel(isOpen);
  }, []);

  const setDisabilityFriendly = React.useCallback((disabilityFriendly: keyof MappedRouteAccessibility) => {
    setRouteDisabilityFriendly(disabilityFriendly);
  }, []);

  const setWaypointVisibility = React.useCallback((waypoint: Extract<PointType, 'aPoint' | 'bPoint'>, visible: boolean) => {
    if (waypoint === 'aPoint') {
      setAPointVisible(visible);
    } else if (waypoint === 'bPoint') {
      setBPointVisible(visible);
    }
  }, []);

  const setPointFromMap = React.useCallback((point: Point) => {
    savePointFromMap(point);
  }, []);

  const dispatchValue = React.useMemo(() => (
    {savePoint, setOpenRoutePanel, setDisabilityFriendly, setWaypointVisibility, setPointFromMap}),
    [savePoint, setOpenRoutePanel, setDisabilityFriendly, setWaypointVisibility, setPointFromMap]
  );

  return (
    <RouteStateContext.Provider value={stateValue}>
      <RouteDispatchContext.Provider value={dispatchValue}>{children}</RouteDispatchContext.Provider>
    </RouteStateContext.Provider>
  )
});

export const useRouteState = () => {
  const context = React.useContext(RouteStateContext);

  if (!context) {
    throw new Error('useRouteState must be used within the RouteProvider');
  }

  return context;
};

export const useRouteDispatch = () => {
  const context = React.useContext(RouteDispatchContext);

  if (!context) {
    throw new Error('useRouteDispatch must be used within the RouteProvider');
  }

  return context;
};
