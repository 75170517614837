import { apiUrl } from '@/settings';
import { getDefaultLanguage } from '@/settings/i18n/i18n';

import { httpErrorHandler } from './httpErrorHandler';

type RequestType = 'get' | 'post' | 'put' | 'delete';

interface IPagination {
  pagination: {
    itemsPerPage: number;
    page: number;
    pages: number;
    total: number;
  };
}

export type ErrorResponse = { message: string; errors: Record<string, string[]> };
export type SuccessResponse<T> = { status: 'success'; data: T };
export type SuccessResponsePagination<T> = SuccessResponse<T> & IPagination;

const customHeader = (isFormData: boolean) => {
  const lang = getDefaultLanguage();

  const header = new Headers({
    Accept: 'application/json',
    lang: lang,
  });

  if (!isFormData) {
    header.set('Content-Type', 'application/json');
  }
  return header;
};

const processData = (data?: object) => {
  if (!data) {
    return undefined;
  } else if (data instanceof FormData) {
    return data;
  }

  return JSON.stringify(data);
};

const fetchData = async (method: RequestType, url: string, data?: object): Promise<Response> => {
  const isFormData = data instanceof FormData;

  const response = await fetch(`${apiUrl}${url}`, {
    method,
    headers: customHeader(isFormData),
    body: processData(data),
  });

  if (response.ok) {
    return response;
  }

  throw await httpErrorHandler(response);
};

const processJSON = async <ResponseObject>(response: Response) => (await response.json()) as ResponseObject;

const apiWrapper =
  (method: RequestType) =>
  async <ResponseObjectType>(url: string, data?: object) => {
    const response = await fetchData(method, url, data);
    const successResponse = await processJSON<SuccessResponse<ResponseObjectType>>(response);

    return successResponse.data;
  };

const apiWrapperPagination =
  (method: RequestType) =>
  async <ResponseObjectType>(url: string, data?: object) => {
    const response = await fetchData(method, url, data);
    const successResponse = await processJSON<SuccessResponsePagination<ResponseObjectType>>(response);

    return {
      data: successResponse.data,
      pagination: successResponse.pagination,
    };
  };

class HTTP {
  static get = apiWrapper('get');
  static post = apiWrapper('post');
  static put = apiWrapper('put');
  static delete = apiWrapper('delete');

  static getPagination = apiWrapperPagination('get');
}

export default HTTP;
