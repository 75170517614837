import type { Polygon } from '@turf/helpers';

export interface IProfile {
  id: number;
  key: number;
  label: string;
}

export interface IConfiguration {
  availableProfiles: IProfile[];
  minZoom: number;
  maxZoom: number;
  boundingBoxNorthWestLat: number;
  boundingBoxNorthWestLng: number;
  boundingBoxSouthEastLat: number;
  boundingBoxSouthEastLng: number;
  help: string;
  privacyPolicy: string;
}

export interface IGeoCategory {
  id: number;
  color: string;
  icon: string;
  iconPrimary: string;
  iconWhite: string;
  drawIcon: boolean;
  isVisibleInCategoryBar: boolean;
  name: string;
  range: number;
}

export class IGeoObject {
  id: number;
  geometry: Polygon;
  properties: {
    name: string;
    categoryGeo: number;
    location: string;
    minZoom: number;
    maxZoom: number;
    description: string;
    profile: number;
    isVisible: boolean;
    typeKey: string;
    image?: string | null; // only on building type
    availableProfiles?: number[];
  };
  center: [number, number];
  naviPoint: [number, number];
  keyName?: CustomMapObject['keyName'];

  constructor(mapObject: CustomMapObject, name: string) {
    this.id = -1;
    this.geometry = {} as Polygon;
    this.properties = {} as IGeoObject['properties'];
    this.properties.name = name;
    this.properties.profile = Number(mapObject.profile);
    this.center = [Number(mapObject.coords.longitude), Number(mapObject.coords.latitude)];
    this.naviPoint = [Number(mapObject.coords.longitude), Number(mapObject.coords.latitude)];
    this.keyName = mapObject.keyName;
  }
}

export interface IBuilding {
  id: number;
  availableProfiles: number[];
  geometry: Polygon;
}

export interface ISearchResult {
  id: number;
  properties: {
    name: string;
    subname: string;
    category: {
      id: number;
      name: string;
      path: string;
      pathPrimary: string;
      pathWhite: string;
    };
  };
}

export interface ICategorySearch {
  extent: {
    northWestLat: number;
    northWestLon: number;
    southEastLat: number;
    southEastLon: number;
  };
  profile: number;
}

export interface ICategorySearchRequest {
  viewRange: {
    northWestLat: number;
    northWestLon: number;
    southEastLat: number;
    southEastLon: number;
  };
  profile: ICategorySearch['profile'];
  categoryId: IGeoCategory['id'];
}

export class RoutePoint {
  x: number;
  y: number;
  p: number;

  constructor(mapObject: IGeoObject) {
    this.x = mapObject.naviPoint[0];
    this.y = mapObject.naviPoint[1];
    this.p = mapObject.properties.profile;
  }
}

export interface RoutePoints {
  nodeList: [RoutePoint, RoutePoint];
  intermediateNodes?: [RoutePoint] | [RoutePoint, RoutePoint];
  disabilityFriendly: 'NORMAL' | 'PREFERLIFT' | 'WHEELCHAIR';
}

export interface RouterRoute {
  travelTime: number;
  travelDistance: number;
  response: {
    route: RouteNode[];
  };
}

export interface RouteSubnode extends RoutePoint {
  id: number;
  parent_id: number;
  i: string;
  t: string;
  icon?: string;
}

export interface RouteNode extends RoutePoint {
  id: number;
  i: string;
  t: string;
  path?: RouteSubnode[];
  icon?: string;
}

export type RouterErrorCode = 3001 | 3002 | 3003 | 3004 | 3005 | 3006 | 3007 | 3012;
export const routerErrorCodes: [
  RouterErrorCode,
  RouterErrorCode,
  RouterErrorCode,
  RouterErrorCode,
  RouterErrorCode,
  RouterErrorCode,
  RouterErrorCode,
  RouterErrorCode,
] = [3001, 3002, 3003, 3004, 3005, 3006, 3007, 3012];

export type Point = [number, number];

export class Coords {
  latitude: number;
  longitude: number;

  constructor(point: Point) {
    this.latitude = point[1];
    this.longitude = point[0];
  }
}

export interface MapRoute {
  profile: number;
  coords: number[][];
}

export type MappedRouteAccessibility = {
  [key in 1 | 2 | 3]: RoutePoints['disabilityFriendly'];
};

export const mappedRouteAccessibility: MappedRouteAccessibility = {
  1: 'NORMAL',
  2: 'PREFERLIFT',
  3: 'WHEELCHAIR',
};

export type CustomMapObject =
  | {type: 'MY_CAR'; keyName: 'myCar'; coords: {latitude: string, longitude: string}; profile: string}
  | {type: 'MY_LOCATION'; keyName: 'myLocation'; coords: {latitude: string, longitude: string}; profile: string}
  | {type: 'POINT_FROM_MAP'; keyName: 'mapPoint'; coords: {latitude: string, longitude: string}; profile: string};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCustomMapObject = (customMapObject: any): customMapObject is CustomMapObject => {
  return customMapObject?.type === 'MY_CAR' || customMapObject?.type === 'MY_LOCATION' || customMapObject?.type === 'POINT_FROM_MAP';
};

export type SharedRoute = {
  startingPoint: string | CustomMapObject;
  destinationPoint: string | CustomMapObject;
  aPoint?: string | CustomMapObject;
  bPoint?: string | CustomMapObject;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSharedRoute = (sharedRoute: any): sharedRoute is SharedRoute => {
  return typeof sharedRoute === 'object' && sharedRoute.startingPoint && sharedRoute.destinationPoint;
};
