import { useTranslation } from 'react-i18next';

import { Icon } from '@/ui/Icon';
import { Text } from '@/ui/Text';

import { EmptyContainer } from './styles';
import emptyIcon from '@/assets/icons/empty.svg';

export const Empty = () => {
  const { t } = useTranslation();

  return (
    <EmptyContainer>
      <Icon src={emptyIcon} size={[42, 42]} />
      <p>
        <Text weight={400} size={12} palette={{ primary: 100 }}>
          {t('global.empty')}
        </Text>
      </p>
    </EmptyContainer>
  );
};
