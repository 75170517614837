import { ICategorySearch, ICategorySearchRequest, IConfiguration, IGeoCategory, IGeoObject, ISearchResult, IBuilding, RoutePoints, RouterRoute } from './types';

import HTTP from '@/utils/http';

export const mapService = {
  getConfiguration: () => HTTP.get<IConfiguration>(`configuration`),
  getGeoObjects: () => HTTP.get<IGeoObject[]>(`geo/roi`),
  getGeoCategories: () => HTTP.get<IGeoCategory[]>(`geo/categories`),
  getBuildings: () => HTTP.get<IBuilding[]>('buildings-profile'),
  getSearchResults: (searchParams: URLSearchParams) => HTTP.getPagination<ISearchResult[]>(`search?${searchParams.toString()}`),
  getCategorySearch: (data: ICategorySearchRequest) => HTTP.post<ICategorySearch>(`area-search`, data),
  getMapRoute: (routePoints: RoutePoints) => HTTP.post<RouterRoute>('best-routing-match', routePoints),
};
