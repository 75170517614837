import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import type { ICategorySearchRequest } from './types';

import { mapService } from './service';

export const useConfigurationQuery = () =>
  useQuery({
    queryKey: ['configuration'],
    queryFn: () => mapService.getConfiguration(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

export const useGeoObjectsQuery = () => {
  const { i18n } = useTranslation();

  return useQuery({
    queryKey: ['geo', 'objects', i18n.resolvedLanguage],
    queryFn: () => mapService.getGeoObjects(),
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
};

export const useGeoCategoriesQuery = () => {
  const { i18n } = useTranslation();

  return useQuery({
    queryKey: ['geo', 'categories', i18n.resolvedLanguage],
    queryFn: () => mapService.getGeoCategories(),
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
};

export const useBuildingsQuery = () =>
  useQuery({
    queryKey: ['buildings'],
    queryFn: () => mapService.getBuildings(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

export const useSearchResultsQuery = (search: string, mapCenter?: [number, number]) => {
  const { i18n } = useTranslation();

  const mapCenterString = mapCenter ? mapCenter.join(',') : null;

  const searchParams = new URLSearchParams({ search });

  mapCenterString && searchParams.append('map_center', mapCenterString);

  const { isLoading, isFetching, isFetched, data, hasNextPage, fetchNextPage, refetch, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['geo', 'search', search, mapCenter, i18n.resolvedLanguage, searchParams],
    queryFn: async ({ pageParam }) => {
      searchParams.append('page', pageParam ?? '1');
      return await mapService.getSearchResults(searchParams);
    },
    getNextPageParam: lastPage =>
      lastPage.pagination.page < lastPage.pagination.pages ? lastPage.pagination.page + 1 : undefined,
    select: data => {
      return { pages: data.pages.flatMap(page => page.data), pageParams: data.pageParams };
    },
    enabled: !!search,
    cacheTime: 0,
  });

  const nextListPage = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const refreshList = useCallback(() => {
    refetch();
  }, [refetch]);

  return { isLoading, isFetching, isFetched, data, nextListPage, refreshList, hasNextPage, isFetchingNextPage };
};

export const useCategorySearchMutation = () =>
  useMutation({
    mutationFn: (data: ICategorySearchRequest) => mapService.getCategorySearch(data),
  });
