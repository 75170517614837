import styled from 'styled-components';
import { breakpoints } from '@/settings/globalStyles/breakpoints';

export const FullscreenButton = styled.button`
  z-index: 10;
  position: absolute;
  right: 25px;
  bottom: 45px;

  height: 35px;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  gap: 1rem;
  background-color: ${({ theme }) => theme.palette.primary[100]};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  border: none;
  cursor: pointer;

  @media (max-width: ${breakpoints.md}) {
    right: 15px;
    bottom: 15px;
  }
`;
