import { useState } from 'react';
import isEmpty from 'lodash.isempty';

import { useMapStateContext } from '../../Context/useMapContext';

import { Icon } from '@/ui/Icon';
import { MapButtonControl } from '@/ui/Buttons';
import { ProfilesItem } from './ProfilesItem';
import { useAvailableProfiles } from '@/hooks/useAvailableProfiles';

import LayersIcon from '@/assets/icons/layers.svg';
import { Container, ProfileListContainer, ProfileList } from './styles';
import { useTranslation } from 'react-i18next';

export const Profiles = (): JSX.Element => {
  const {t} = useTranslation();
  const { configuration, mapProfile } = useMapStateContext();
  const allProfiles = configuration?.availableProfiles;
  const {availableProfiles} = useAvailableProfiles(allProfiles);
  const [open, setOpen] = useState(false);

  return (
    <>
      {allProfiles && !isEmpty(allProfiles) && (
        <>
          <Container>
            <MapButtonControl
              variant='BADGE'
              badge={allProfiles.find(profile => profile.key.toString() === mapProfile)?.label ?? ''}
              onClick={() => setOpen(prevState => !prevState)}
              icon={<Icon src={LayersIcon} size={[28, 28]} />}
              aria-label={t('labels.floorList')}
              aria-details={`${t('labels.currentlyActiveFloor')}: ${allProfiles.find(profile => profile.key.toString() === mapProfile)?.label ?? ''}`}
              aria-controls="profileList"
              aria-expanded={open}
            />
          </Container>
          {open && (
            <ProfileListContainer id="profileList">
              <ProfileList>
                {[...allProfiles].reverse().map(profile => (
                  <ProfilesItem
                    key={profile.key} profile={profile}
                    active={profile.key.toString() === mapProfile}
                    disabled={availableProfiles ? !availableProfiles.includes(profile.key) : true}
                  />
                ))}
              </ProfileList>
            </ProfileListContainer>
          )}
        </>
      )}
    </>
  );
};
