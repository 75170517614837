import { useTheme } from 'styled-components';
import ContentLoader from 'react-content-loader';

import type { ReactNode } from 'react';

import { Box } from '@/ui/Box';
import { Icon } from '@/ui/Icon';

import logo from '@/assets/logo.svg';
import { MainLoaderContainer } from './styles';

export const SearchResultLoader = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box pl={8} pt={8} pb={8} pr={8}>
      <ContentLoader
        speed={1.2}
        height={44}
        backgroundColor={theme.palette.light['75']}
        foregroundColor={theme.palette.background['100']}
      >
        <rect width={44} height={44} rx={8} ry={8} />
        <rect width={200} height={14} x={52} y={4} rx={4} ry={4} />
        <rect width={200} height={14} x={52} y={24} rx={4} ry={4} />
      </ContentLoader>
    </Box>
  );
};

interface MainLoaderProps {
  loading: boolean;
  children: ReactNode;
}

export const MainLoader = ({ loading, children }: MainLoaderProps): JSX.Element => (
  <>
    {loading ? (
      <MainLoaderContainer>
        <Icon src={logo} size={[256, 256]} />
      </MainLoaderContainer>
    ) : (
      children
    )}
  </>
);
