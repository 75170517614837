import { useRef, useState, useEffect, ReactNode, useMemo } from 'react';
import { Map as MapOl, View as ViewOl } from 'ol';
import { XYZ as XYZSourceOl, Vector as VectorSourceOl } from 'ol/source';
import { GeoJSON } from 'ol/format';
import { fromLonLat, toLonLat } from 'ol/proj';
import { boundingExtent, getBottomRight, getTopLeft } from 'ol/extent';

import { IConfiguration, IGeoCategory, IGeoObject, IBuilding, CustomMapObject } from './types';
import { isCustomMapObject, isSharedRoute } from './types';

import { MapDispatchContext, MapStateContext } from './Context/MapContext';
import { mapConfig } from './config';
import { useCategorySearchMutation } from './queries';
import {
  addIconsByCategory,
  colorFeaturesByCategory,
  colorSelectedObject,
  createIconsFeatureCollection,
  createMapObjectsFeatureCollection,
  filterVisibleFeatures,
  createRouteIcons,
  addRouteIcons,
  createRouteLines,
  styleRouteLines,
  createCustomMapObjectIcon,
  addCustomMapObjectIcon,
} from './utils';

import { MapStyled } from './styles';
import 'ol/ol.css';
import { useRouteState, useRouteDispatch } from './Context/useRoute';
import qs from 'qs';
import i18n from '@/settings/i18n/i18n';

interface MapContainerProps {
  geoObjects: IGeoObject[];
  geoCategories: IGeoCategory[];
  configuration: IConfiguration;
  buildings: IBuilding[];
  children: ReactNode;
}

export const MapContainer = ({ geoObjects, geoCategories, configuration, buildings, children }: MapContainerProps): JSX.Element => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [mapProfile, setMapProfile] = useState(mapConfig.defaultProfile);
  const [map, setMap] = useState<MapOl>();
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [zoom, setZoom] = useState(mapConfig.zoom);
  const [selectedObject, setSelectedObject] = useState<IGeoObject | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<IGeoCategory | null>(null);
  const [selectedCustomMapObject, setSelectedCustomMapObject] = useState<CustomMapObject>();
  const {startingPoint, destinationPoint, aPoint, bPoint, route} = useRouteState();
  const {setOpenRoutePanel, savePoint} = useRouteDispatch();
  const tileSource = useMemo<XYZSourceOl>(
    () =>
      new XYZSourceOl({
        url: `${mapConfig.xyz}/${`p${mapProfile}`}/${mapConfig.xyzEnd}`,
        minZoom: configuration.minZoom,
        maxZoom: configuration.maxZoom,
      }),
    [mapProfile, configuration],
  );
  const vectorSource = useMemo<VectorSourceOl>(
    () =>
      new VectorSourceOl({
        format: new GeoJSON(),
      }),
    [],
  );
  const { mutate: categorySearch, data: categorySearchData } = useCategorySearchMutation();

  const handleChangeMapProfile = (profile: number) => {
    setMapProfile(profile.toString());
  };

  const handleFullscreen = () => {
    if (mapRef.current) {
      fullscreenMode ? document.exitFullscreen() : mapRef.current.requestFullscreen();
      setFullscreenMode(prevState => !prevState);
    }
  };

  document.onfullscreenchange = () => {
    if (!document.fullscreenElement && fullscreenMode) {
      setFullscreenMode(false);
    }
  };

  const handleZoom = (value: number, scroll?: boolean) => {
    if (scroll) {
      setZoom(value);
      return;
    }

    const view = map?.getView();
    if (!view) {
      return;
    }

    view.cancelAnimations();

    const oldZoom = view.getZoom() ?? mapConfig.zoom;
    const newZoom = oldZoom + value;
    let zoomDiff = newZoom;

    if (newZoom >= configuration.maxZoom) {
      zoomDiff = oldZoom + (configuration.maxZoom - oldZoom);
    } else if (newZoom <= configuration.minZoom) {
      zoomDiff = oldZoom - (oldZoom - configuration.minZoom);
    }

    view.setZoom(zoomDiff);
    setZoom(zoomDiff);
  };

  const handleSelectObject = (id: string | number | null | undefined, type: 'search' | 'map') => {
    const url = new URL(window.location.href);
    const sharedObjectId = url.searchParams.get('geo');

    if (selectedCustomMapObject) {
      setSelectedCustomMapObject(undefined);
      window.history.pushState({}, document.title, window.location.origin);
    }

    if (sharedObjectId && !id) {
      window.history.pushState({}, document.title, window.location.origin);
    }
    
    if (!id) {
      setSelectedObject(null);
      return;
    }

    const objectId = Number.parseInt(id.toString());
    const selectedObject = geoObjects.find(geoObject => geoObject.id === objectId);

    if (sharedObjectId && !selectedObject) {
      window.history.pushState({}, document.title, window.location.origin);
    }

    if (!selectedObject) {
      setSelectedObject(null);
      return;
    }

    if (sharedObjectId && selectedObject.id !== Number(sharedObjectId)) {
      window.history.pushState({}, document.title, window.location.origin);
    }

    setSelectedObject(selectedObject);

    if (type === 'search') {
      const view = map?.getView();
      handleChangeMapProfile(selectedObject.properties.profile);
      if (!view) {
        return;
      }

      view.cancelAnimations();

      view.animate({
        zoom: (selectedObject.properties.maxZoom + selectedObject.properties.minZoom) / 2,
        center: fromLonLat(selectedObject.center),
      });
    }
  };

  const handleSelectedCategory = (category: IGeoCategory) => {
    if (!map) {
      return;
    }

    handleSelectObject(null, 'map');

    if (selectedCategory && selectedCategory.id === category.id) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(category);

      const extent = map.getView().calculateExtent();

      const topLeft = toLonLat(getTopLeft(extent));
      const bottomRight = toLonLat(getBottomRight(extent));

      categorySearch({
        profile: Number.parseInt(mapProfile),
        viewRange: {
          northWestLat: topLeft[1],
          northWestLon: topLeft[0],
          southEastLat: bottomRight[1],
          southEastLon: bottomRight[0],
        },
        categoryId: category.id,
      });
    }
  };

  const handleSelectCustomMapObject = (customMapObject: CustomMapObject) => {
    const view = map?.getView();

    setSelectedCustomMapObject(customMapObject);
    handleChangeMapProfile(Number(customMapObject.profile));

    if (!view) {
      return;
    }

    view.cancelAnimations();

    view.animate({
      center: fromLonLat([Number(customMapObject.coords.longitude), Number(customMapObject.coords.latitude)]),
    });
  };

  useEffect(() => {
    const options = {
      view: new ViewOl({
        center: fromLonLat(mapConfig.center),
        zoom: mapConfig.zoom,
        minZoom: configuration.minZoom ?? mapConfig.minZoom,
        maxZoom: configuration.maxZoom ?? mapConfig.maxZoom,
        constrainResolution: mapConfig.constrainResolution,
      }),
      layers: [],
      controls: [],
      overlays: [],
    };

    const mapObject = new MapOl(options);

    if (mapRef.current) {
      mapObject.setTarget(mapRef.current);
      setMap(mapObject);
    }

    return () => {
      mapObject.setTarget(undefined);
    };
  }, [configuration]);

  useEffect(() => {
    const visibleFeatures = filterVisibleFeatures(geoObjects, geoCategories, mapProfile, zoom);
    const featuresGeoJson = new GeoJSON().readFeatures(createMapObjectsFeatureCollection(visibleFeatures), {
      featureProjection: mapConfig.featureProjection,
    });
    const iconsGeoJson = new GeoJSON().readFeatures(
      createIconsFeatureCollection(visibleFeatures, geoObjects, geoCategories, mapProfile, selectedCategory),
      {
        featureProjection: mapConfig.featureProjection,
      },
    );
    const routeIcons = new GeoJSON().readFeatures(
      createRouteIcons(startingPoint, destinationPoint, aPoint, bPoint, route),
      {
        featureProjection: mapConfig.featureProjection,
      },
    );
    const routeLines = new GeoJSON().readFeatures(
      createRouteLines(route),
      {
        featureProjection: mapConfig.featureProjection,
      },
    );
    const customMapObjectIcon = new GeoJSON().readFeatures(
      createCustomMapObjectIcon(selectedCustomMapObject, mapProfile),
      {
        featureProjection: mapConfig.featureProjection,
      },
    );

    colorFeaturesByCategory(featuresGeoJson, geoCategories);
    addIconsByCategory(iconsGeoJson, geoCategories, zoom);
    colorSelectedObject(featuresGeoJson, iconsGeoJson, selectedObject, geoCategories, zoom, geoObjects);
    addRouteIcons(routeIcons, zoom, mapProfile);
    styleRouteLines(routeLines, mapProfile);
    addCustomMapObjectIcon(customMapObjectIcon, zoom);

    vectorSource.clear();
    vectorSource.addFeatures([...featuresGeoJson, ...iconsGeoJson, ...routeIcons, ...routeLines, ...customMapObjectIcon]);
  }, [geoObjects, vectorSource, geoCategories, mapProfile, zoom, selectedObject, selectedCategory, startingPoint, destinationPoint, aPoint, bPoint, route, selectedCustomMapObject]);

  useEffect(() => {
    if (!categorySearchData || !map) {
      return;
    }

    const view = map.getView();
    const { profile, extent } = categorySearchData;

    if (!view) {
      return;
    }

    handleChangeMapProfile(profile);

    const extentOl = boundingExtent([
      fromLonLat([extent.northWestLon, extent.northWestLat]),
      fromLonLat([extent.southEastLon, extent.southEastLat]),
    ]);

    // maybe change to animate to center of extent
    view.fit(extentOl, { duration: 1000 });
  }, [map, categorySearchData]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const sharedObjectId = url.searchParams.get('geo');
    const sharedLink = qs.parse(window.location.search.split('?')[1]);

    if (isCustomMapObject(sharedLink.geo)) {
      handleSelectCustomMapObject(sharedLink.geo);
    }

    if (sharedObjectId) {
      handleSelectObject(sharedObjectId, 'search');
    }

    if (isSharedRoute(sharedLink.route)) {
      const sharedRoute = sharedLink.route;
      
      if (Number(sharedRoute.startingPoint)) {
        savePoint('startingPoint', geoObjects.find((geoObject) => geoObject.id === Number(sharedRoute.startingPoint)));
      } else if (isCustomMapObject(sharedRoute.startingPoint)) {
        savePoint('startingPoint', new IGeoObject(sharedRoute.startingPoint, i18n.t(`map.customMapObjectNames.${sharedRoute.startingPoint.keyName}`)));
      }

      if (Number(sharedRoute.destinationPoint)) {
        savePoint('destinationPoint', geoObjects.find((geoObject) => geoObject.id === Number(sharedRoute.destinationPoint)));
      } else if (isCustomMapObject(sharedRoute.destinationPoint)) {
        savePoint('destinationPoint', new IGeoObject(sharedRoute.destinationPoint, i18n.t(`map.customMapObjectNames.${sharedRoute.destinationPoint.keyName}`)));
      }

      if (Number(sharedRoute.aPoint)) {
        savePoint('aPoint', geoObjects.find((geoObject) => geoObject.id === Number(sharedRoute.aPoint)));
      } else if (isCustomMapObject(sharedRoute.aPoint)) {
        savePoint('aPoint', new IGeoObject(sharedRoute.aPoint, i18n.t(`map.customMapObjectNames.${sharedRoute.aPoint.keyName}`)));
      }

      if (Number(sharedRoute.bPoint)) {
        savePoint('bPoint', geoObjects.find((geoObject) => geoObject.id === Number(sharedRoute.bPoint)));
      } else if (isCustomMapObject(sharedRoute.bPoint)) {
        savePoint('bPoint', new IGeoObject(sharedRoute.bPoint, i18n.t(`map.customMapObjectNames.${sharedRoute.bPoint.keyName}`)));
      }

      setOpenRoutePanel(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    const view = map?.getView();
    const extent = vectorSource.getFeatureById('plannedRoute')?.getGeometry()?.getExtent();

    if (route && extent && startingPoint && destinationPoint) {
      setMapProfile(startingPoint.properties.profile.toString());
      view?.fit(extent, {padding: [50, 50, 50, 50], duration: 1000});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, route]);

  return (
    <MapStateContext.Provider
      value={{
        map,
        tileSource,
        vectorSource,
        mapProfile,
        configuration,
        fullscreenMode,
        zoom,
        buildings,
        geoObjects,
        geoCategories,
        selectedObject,
        selectedCategory,
        selectedCustomMapObject,
      }}
    >
      <MapDispatchContext.Provider
        value={{ handleChangeMapProfile, handleFullscreen, handleZoom, handleSelectObject, handleSelectedCategory }}
      >
        <MapStyled ref={mapRef}>{children}</MapStyled>
      </MapDispatchContext.Provider>
    </MapStateContext.Provider>
  );
};
