import { createContext } from 'react';
import { Map as MapOl } from 'ol';
import { XYZ as XYZSourceOl, Vector as VectorSourceOl } from 'ol/source';

import type { IConfiguration, IGeoCategory, IGeoObject, IBuilding, CustomMapObject } from '../types';

interface IMapStateContext {
  map?: MapOl;
  tileSource: XYZSourceOl;
  vectorSource: VectorSourceOl;
  mapProfile: string;
  fullscreenMode: boolean;
  configuration?: IConfiguration;
  zoom: number;
  buildings: IBuilding[];
  geoObjects: IGeoObject[];
  geoCategories: IGeoCategory[];
  selectedObject: IGeoObject | null;
  selectedCategory: IGeoCategory | null;
  selectedCustomMapObject: CustomMapObject | undefined;
}

interface IMapDispatchContext {
  handleChangeMapProfile: (arg1: number) => void;
  handleFullscreen: () => void;
  handleZoom: (arg1: number, arg2?: boolean) => void;
  handleSelectObject: (arg1: string | number | null | undefined, arg2: 'search' | 'map') => void;
  handleSelectedCategory: (arg1: IGeoCategory) => void;
}

export const MapStateContext = createContext<IMapStateContext | undefined>(undefined);
export const MapDispatchContext = createContext<IMapDispatchContext | undefined>(undefined);
