import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/ui/Text';
import { Icon } from '@/ui/Icon';
import { Box } from '@/ui/Box';

import closeIcon from '@/assets/icons/close.svg';
import noFacilitiesIcon from '@/assets/icons/noFacilities.png';
import elevatorIcon from '@/assets/icons/elevator.png';
import wheelchairIcon from '@/assets/icons/wheelchair.png';
import addWaypointIcon from '@/assets/icons/addWaypoint.png';
import {
  modalCustomStyles,
  ModalContentContainer,
  CloseModalButton,
  ModalButton,
  ModalButtonIcon,
} from './styles';
import Modal from 'react-modal';
import { toast } from 'react-hot-toast';
import { MappedRouteAccessibility } from '../../types';
import i18n from '@/settings/i18n/i18n';
import { useRouteState } from '../../Context/useRoute';
import { useRouteDispatch } from '../../Context/useRoute';

interface OptionsModalProps {
  open: boolean;
  setClose: () => void;
}

type RouteAccessibility = {
  [key in keyof MappedRouteAccessibility]: {text: string; icon: React.ReactNode};
};

const routeAccessibility: RouteAccessibility = {
  1: {
    text: i18n.t('route.options.noFacilities'),
    icon: <ModalButtonIcon size={[24, 24]} src={noFacilitiesIcon} />,
  },
  2: {
    text: i18n.t('route.options.preferringElevators'),
    icon: <ModalButtonIcon size={[24, 24]} src={elevatorIcon} />,
  },
  3: {
    text: i18n.t('route.options.useWheelchair'),
    icon: <ModalButtonIcon size={[24, 24]} src={wheelchairIcon} />,
  },
};

export const OptionsModal = React.memo(({open, setClose}: OptionsModalProps) => {
  const {t} = useTranslation();
  const {disabilityFriendly, aPointVisible, bPointVisible} = useRouteState();
  const {setDisabilityFriendly, setWaypointVisibility} = useRouteDispatch();

  const handleAddWaypoint = React.useCallback(() => {
    if (aPointVisible && bPointVisible) {
      toast.error(t('route.maxWaypoints'));
    } else if (aPointVisible) {
      setWaypointVisibility('bPoint', true);
    } else {
      setWaypointVisibility('aPoint', true);
    }
  }, [t, aPointVisible, bPointVisible, setWaypointVisibility]);

  const handleRouteAccessibility = React.useCallback(() => {
    const value = disabilityFriendly + 1;
    setDisabilityFriendly(value > 3 ? 1 : (value as keyof MappedRouteAccessibility));
  }, [disabilityFriendly, setDisabilityFriendly]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={setClose}
      style={modalCustomStyles}
    >
      <ModalContentContainer>
        <CloseModalButton onClick={setClose}>
          <Text srOnly={true}>{t('labels.closeRouteOptions')}</Text>
          <Icon src={closeIcon} size={[32, 32]} />
        </CloseModalButton>
        <Box mb={35}>
          <h3>
            <Text size={20} weight={600} palette={{primary: 100}} transform="uppercase">
              {t('labels.routeOptions')}
            </Text>
          </h3>
        </Box>
        <ModalButton onClick={handleAddWaypoint}>
          <ModalButtonIcon size={[24, 24]} src={addWaypointIcon} />
          <Text weight={400} size={13} palette={{ primary: 200 }}>
            {t('route.options.waypointTitle')}
          </Text>
        </ModalButton>
        <ModalButton onClick={handleRouteAccessibility}>
          {routeAccessibility[disabilityFriendly].icon}
          <Text weight={400} size={13} palette={{ primary: 200 }}>
            {t('route.options.routesAccessibilityTitle')}:{' '}
          </Text>
          <Text weight={700} size={13} palette={{ primary: 200 }}>
            {routeAccessibility[disabilityFriendly].text}
          </Text>
        </ModalButton>
      </ModalContentContainer>
    </Modal>
  );
});
