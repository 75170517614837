import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { Fill, Icon, RegularShape, Stroke, Style, Text } from 'ol/style';
import { feature, featureCollection, point, lineString, Polygon, Feature as GeoFeature } from '@turf/helpers';
import { getBottomRight, getTopLeft, Extent } from 'ol/extent';
import { toLonLat } from 'ol/proj';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';

import type { IGeoCategory, IGeoObject, RouterErrorCode, MapRoute, RouteNode, CustomMapObject } from './types';
import { routerErrorCodes } from './types';

import { mapStyles } from './config';
import startingPointLightIcon from '@/assets/icons/startingPointLight.png';
import destinationPointLightIcon from '@/assets/icons/destinationPointLight.png';
import aPointIcon from '@/assets/icons/aWaypoint.png';
import bPointIcon from '@/assets/icons/bWaypoint.png';
import userLocationIcon from '@/assets/icons/userLocation.png';
import carLocationIcon from '@/assets/icons/userCar.png';

const inZoomRange = (number: number | undefined, range: [number, number]): boolean => {
  if (range[0] > range[1]) {
    throw new Error('The value of lower range cannot be greater than the value of the upper range');
  }

  if (!number) {
    return false;
  }

  const numberInt = Math.round(number);

  return numberInt >= range[0] && numberInt <= range[1];
};

const inMapProfile = (mapProfile: string, objectMapProfile: number, type: string) => {
  if (['campus', 'building'].includes(type)) {
    return true;
  }

  return objectMapProfile.toString() === mapProfile;
};

export const routeFromRouterToLineCoords = (route: RouteNode[] | undefined): MapRoute[] | undefined => {
  if (!route) {
    return undefined;
  }

  const lineCoords: MapRoute[] = [];
  const routeLength = route.length;
  let currentRoute: MapRoute = { profile: route[0].p, coords: [] };
  let index = 0;

  for (const node of route) {
    index += 1;

    if (node.p !== currentRoute.profile) {
      lineCoords.push(currentRoute);
      currentRoute = { profile: node.p, coords: [] };
    }

    if (node.path) {
      currentRoute.coords = [...currentRoute.coords, ...node.path.map(subnode => [subnode.x, subnode.y]), [node.x, node.y]];
    } else {
      currentRoute.coords = [...currentRoute.coords, [node.x, node.y]];
    }

    if (index === routeLength) {
      lineCoords.push(currentRoute);
    }
  }

  return lineCoords;
};

export const filterVisibleFeatures = (
  geoObjects: IGeoObject[],
  geoCategories: IGeoCategory[],
  mapProfile: string,
  zoom: number,
) =>
  geoObjects.filter(geoObject => {
    const category = geoCategories.find(geoCategory => geoObject.properties.categoryGeo === geoCategory.id);
    if (
      category &&
      geoObject.properties.isVisible &&
      inMapProfile(mapProfile, geoObject.properties.profile, geoObject.properties.typeKey) &&
      inZoomRange(zoom, [geoObject.properties.minZoom, geoObject.properties.maxZoom])
    ) {
      return true;
    }

    return false;
  });

export const createMapObjectsFeatureCollection = (geoObjects: IGeoObject[]) =>
  featureCollection([...geoObjects.map(geoObject => feature(geoObject.geometry, geoObject.properties, { id: geoObject.id }))]);

export const createRouteLines = (route: RouteNode[] | undefined) => {
  const lines = routeFromRouterToLineCoords(route);

  if (lines) {
    return featureCollection([
      ...lines.map((line, index) =>
        lineString(
          line.coords,
          {
            profile: line.profile,
          },
          {
            id: `plannedRoute_${index}`,
          },
        ),
      ),
    ]);
  } else {
    return featureCollection([]);
  }
};

export const createIconsFeatureCollection = (
  geoObjects: IGeoObject[],
  allGeoObjects: IGeoObject[],
  geoCategories: IGeoCategory[],
  mapProfile: string,
  selectedCategory: IGeoCategory | null,
) => {
  const filteredBySelectedCategory = selectedCategory
    ? allGeoObjects.filter(
        geoObject =>
          geoObject.properties.categoryGeo === selectedCategory.id &&
          inMapProfile(mapProfile, geoObject.properties.profile, geoObject.properties.typeKey),
      )
    : geoObjects;

  const filterByCategory = filteredBySelectedCategory.filter(geoObject => {
    const category = geoCategories.find(geoCategory => geoObject.properties.categoryGeo === geoCategory.id);

    if (category && category.drawIcon) {
      return true;
    }

    return false;
  });

  return featureCollection([
    ...filterByCategory.map(geoObject =>
      point(
        geoObject.center,
        {
          categoryGeo: geoObject.properties.categoryGeo,
          icon: true,
          text: geoObject.properties.name,
          type: geoObject.properties.typeKey,
        },
        { id: `${geoObject.id}_icon` },
      ),
    ),
  ]);
};

export const createRouteIcons = (
  startingPoint: IGeoObject | undefined,
  destinationPoint: IGeoObject | undefined,
  aPoint: IGeoObject | undefined,
  bPoint: IGeoObject | undefined,
  route: RouteNode[] | undefined,
) => {
  const startingPointFeature = startingPoint
    ? point(
        startingPoint.center,
        {
          icon: true,
          type: 'startingPoint',
          profile: startingPoint.properties.profile,
        },
        { id: `${startingPoint.id}_startingPointIcon` },
      )
    : undefined;

  const destinationPointFeature = destinationPoint
    ? point(
        destinationPoint.center,
        {
          icon: true,
          type: 'destinationPoint',
          profile: destinationPoint.properties.profile,
        },
        { id: `${destinationPoint.id}_destinationPointIcon` },
      )
    : undefined;

  const aPointFeature = aPoint
    ? point(
        aPoint.center,
        {
          icon: true,
          type: 'aPoint',
          profile: aPoint.properties.profile,
        },
        { id: `${aPoint.id}_aPointIcon` },
      )
    : undefined;

  const bPointFeature = bPoint
    ? point(
        bPoint.center,
        {
          icon: true,
          type: 'bPoint',
          profile: bPoint.properties.profile,
        },
        { id: `${bPoint.id}_bPointIcon` },
      )
    : undefined;

  const routePointsFeatures = [startingPointFeature, destinationPointFeature, aPointFeature, bPointFeature];
  const filteredRoutePointsFeatures = routePointsFeatures.filter(
    (routePoint): routePoint is Exclude<typeof startingPointFeature, undefined> => routePoint !== undefined,
  );

  route?.forEach(node => {
    if (node.i !== 'start' && node.i !== 'end' && !node.i.includes('middle')) {
      filteredRoutePointsFeatures.push(
        point([node.x, node.y], { icon: true, type: 'routePoint', profile: node.p, iconUrl: node.icon }),
      );
    }
  });

  return featureCollection(filteredRoutePointsFeatures);
};

export const createCustomMapObjectIcon = (customMapObject: CustomMapObject | undefined, mapProfile: string) => {
  const customPoint = customMapObject
    ? point(
      [Number(customMapObject.coords.longitude), Number(customMapObject.coords.latitude)],
      {
        icon: true,
        type: 'customPoint',
        profile: customMapObject.profile,
        iconUrl: customMapObject.type === 'MY_CAR' ? carLocationIcon : userLocationIcon,
      },
      { id: 'customPoint' },
    )
  : undefined;

  return featureCollection(customPoint && customPoint.properties.profile === mapProfile ? [customPoint]: []);
};

export const colorFeaturesByCategory = (features: Feature<Geometry>[], geoCategories: IGeoCategory[]) => {
  features.forEach(feature => {
    const category = geoCategories.find(geoCategory => feature.getProperties().categoryGeo === geoCategory.id);

    category && feature.setStyle(getFeatureStyle(category.color));
  });
};

export const styleRouteLines = (features: Feature<Geometry>[], mapProfile: string) => {
  features.forEach(feature => {
    const { profile } = feature.getProperties();
    const styles = getRouteLineStyle(Number(profile) === Number(mapProfile) ? '#3983FF' : '#B4B4B4');

    feature.setStyle(styles);
  });
};

export const addIconsByCategory = (features: Feature<Geometry>[], geoCategories: IGeoCategory[], zoom: number) => {
  const iconScale = 1 / Math.pow(zoom, 1 / 4);

  features.forEach(feature => {
    const { text, categoryGeo, type } = feature.getProperties();
    const category = geoCategories.find(geoCategory => categoryGeo === geoCategory.id);

    const styles =
      category &&
      (['campus', 'building'].includes(type)
        ? [...getIconStyle(category.iconWhite ? category.iconWhite : category.icon, iconScale), ...getTextStyle(text, iconScale)]
        : getIconStyle(category.iconWhite ? category.iconWhite : category.icon, iconScale));

    feature.setStyle(styles);
  });
};

export const addRouteIcons = (features: Feature<Geometry>[], zoom: number, mapProfile: string) => {
  const iconScale = 1 / Math.pow(zoom, 1 / 4);

  features.forEach(feature => {
    const { type, profile, iconUrl } = feature.getProperties();

    if (type === 'startingPoint') {
      const styles = getRouteIconStyle(
        startingPointLightIcon,
        iconScale,
        Number(profile) === Number(mapProfile) ? '#3983FF' : '#B4B4B4',
      );
      feature.setStyle(styles);
    } else if (type === 'destinationPoint') {
      const styles = getRouteIconStyle(
        destinationPointLightIcon,
        iconScale,
        Number(profile) === Number(mapProfile) ? '#3983FF' : '#B4B4B4',
      );
      feature.setStyle(styles);
    } else if (type === 'aPoint') {
      const styles = getRouteIconStyle(aPointIcon, iconScale, Number(profile) === Number(mapProfile) ? '#FFFFFF' : '#B4B4B4');
      feature.setStyle(styles);
    } else if (type === 'bPoint') {
      const styles = getRouteIconStyle(bPointIcon, iconScale, Number(profile) === Number(mapProfile) ? '#FFFFFF' : '#B4B4B4');
      feature.setStyle(styles);
    } else if (type === 'routePoint') {
      const styles = getRouteIconStyle(iconUrl, iconScale, Number(profile) === Number(mapProfile) ? '#FFFFFF' : '#B4B4B4');
      feature.setStyle(styles);
    }
  });
};

export const addCustomMapObjectIcon = (features: Feature<Geometry>[], zoom: number) => {
  const iconScale = 1 / Math.pow(zoom, 1 / 4);

  features.forEach(feature => {
    const { iconUrl } = feature.getProperties();

    const styles = getCustomMapObjectIconStyle(iconUrl, iconScale, '#D80048');
    feature.setStyle(styles);
  });
};

export const colorSelectedObject = (
  features: Feature<Geometry>[],
  icons: Feature<Geometry>[],
  selectedObjectFromContext: IGeoObject | null,
  geoCategories: IGeoCategory[],
  zoom: number,
  geoObjects: IGeoObject[],
) => {
  const selectedObject = geoObjects.find(geoObject => geoObject.id === selectedObjectFromContext?.id);
  if (selectedObject) {
    const { name, typeKey } = selectedObject.properties;
    const roi = features.find(feature => feature.getId() === selectedObject.id);
    const icon = icons.find(feature => feature.getId() === `${selectedObject.id}_icon`);

    if (roi) {
      const category = geoCategories.find(geoCategory => roi.getProperties().categoryGeo === geoCategory.id);
      roi.setStyle(getFeatureSelectStyle());

      if (icon) {
        const iconScale = 1 / Math.pow(zoom, 1 / 4);

        const styles =
          category &&
          (['campus', 'building'].includes(typeKey)
            ? [
                ...getIconSelectStyle(category.iconWhite ? category.iconWhite : category.icon, iconScale),
                ...getTextStyle(name, iconScale),
              ]
            : getIconSelectStyle(category.iconWhite ? category.iconWhite : category.icon, iconScale));

        icon.setStyle(styles);
      }
    }
  }
};

export const getFeatureStyle = (color: string) =>
  new Style({
    fill: new Fill({ color: `${color}80` }),
    stroke: new Stroke({ color: color, width: mapStyles.selectStrokeWidthObject }),
    zIndex: 0,
  });

export const getFeatureSelectStyle = () =>
  new Style({
    fill: new Fill({ color: mapStyles.selectFillColor }),
    stroke: new Stroke({ color: mapStyles.selectStrokeColor, width: mapStyles.selectStrokeWidthObject }),
    zIndex: 1,
  });

const getRouteLineStyle = (color: string) =>
  new Style({
    stroke: new Stroke({ width: 5, color: color }),
    zIndex: 2,
  });

const getIconStyle = (iconUrl: string, iconScale: number) => [
  new Style({
    image: new RegularShape({
      points: Infinity,
      radius: 48,
      scale: iconScale,
      fill: new Fill({
        color: mapStyles.primaryColor,
      }),
    }),
    zIndex: 2,
  }),
  new Style({
    image: new Icon({
      width: 24,
      height: 24,
      src: iconUrl,
    }),
    zIndex: 2,
  }),
];

const getRouteIconStyle = (iconUrl: string, iconScale: number, color: string) => [
  new Style({
    image: new RegularShape({
      points: Infinity,
      radius: 48,
      scale: iconScale,
      fill: new Fill({
        color: color,
      }),
    }),
    zIndex: 2,
  }),
  new Style({
    image: new Icon({
      width: 24,
      height: 24,
      src: iconUrl,
      crossOrigin: 'anonymous',
      color: '#FFFFFF',
    }),
    zIndex: 2,
  }),
];

const getCustomMapObjectIconStyle = (iconUrl: string, iconScale: number, color: string) => [
  new Style({
    image: new RegularShape({
      points: Infinity,
      radius: 48,
      scale: iconScale,
      fill: new Fill({
        color: color,
      }),
    }),
    zIndex: 2,
  }),
  new Style({
    image: new Icon({
      width: 34,
      height: 34,
      src: iconUrl,
      crossOrigin: 'anonymous',
      color: '#FFFFFF',
    }),
    zIndex: 2,
  }),
];

const getIconSelectStyle = (iconUrl: string, iconScale: number) => [
  ...getIconStyle(iconUrl, iconScale),
  new Style({
    image: new RegularShape({
      points: Infinity,
      radius: 60,
      scale: iconScale,
      stroke: new Stroke({ color: mapStyles.selectStrokeColor, width: 8 }),
    }),
    zIndex: 2,
  }),
];

const getTextStyle = (text: string, iconScale: number) => [
  new Style({
    text: new Text({
      text: createTextWithBreaks(text, 24),
      font: '500 24px Poppins',
      scale: iconScale,
      padding: [4, 8, 0, 8],
      offsetY: 32,
      backgroundFill: new Fill({ color: mapStyles.textBackground }),
      textBaseline: 'top',
      backgroundStroke: new Stroke({
        color: '#939CB2',
        width: 1,
      })
    }),
    zIndex: 2,
  }),
];

const createTextWithBreaks = (text: string, width: number): string => {
  if (text.length <= width) {
    return text;
  }

  let index = width;
  while (index > 0 && text[index] != ' ' && text[index] != '-') {
    index--;
  }

  if (index > 0) {
    const left = text.substring(index, index + 1) == '-' ? text.substring(0, index + 1) : text.substring(0, index);
    const right = text.substring(index + 1);

    return left + '\n' + createTextWithBreaks(right, width);
  }

  return text;
};

export const mapExtentToPolygonBbox = (extent: Extent): GeoFeature<Polygon> => {
  const topLeft = toLonLat(getTopLeft(extent));
  const bottomRight = toLonLat(getBottomRight(extent));
  const line = lineString([topLeft, bottomRight]);

  return bboxPolygon(bbox(line));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRouterErrorCode = (errorCode: any): errorCode is RouterErrorCode => {
  return routerErrorCodes.includes(errorCode);
};
