import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import isEmpty from 'lodash.isempty';

import type { InfiniteData } from '@tanstack/react-query';
import type { ISearchResult } from '../../types';

import { Text } from '@/ui/Text';
import { SearchResultLoader } from '@/ui/Loading';
import { SrOnly } from '@/ui/SrOnly';
import { Empty } from '@/ui/Empty';
import { Icon } from '@/ui/Icon';

import arrowUpIcon from '@/assets/icons/arrowUp.svg';
import {
  InfiniteScrollTrigger,
  Info,
  ResultsRetractorButton,
  SearchContainerItem,
  SearchResult,
  SearchResultIcon,
  SearchResultsContainer,
  Scrollbar,
} from './styles';
import { useTranslation } from 'react-i18next';

interface SearchResultsProps {
  searchResults: InfiniteData<ISearchResult> | undefined;
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  search: string;
  nextListPage: () => void;
  handleChooseElement: (id: number) => void;
  handleClear: () => void;
}

export const SearchResults = ({
  searchResults,
  isLoading,
  hasNextPage,
  search,
  nextListPage,
  handleChooseElement,
  handleClear,
}: SearchResultsProps): JSX.Element => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({});

  useEffect(() => {
    if (inView) {
      nextListPage();
    }
  }, [inView, nextListPage]);

  return (
    <>
      {search && (
        <>
          <SearchContainerItem>
            <Scrollbar>
              <SrOnly>
                <h2>{`${t('labels.searchResults')}: ${search}`}</h2>
              </SrOnly>
              {searchResults && isEmpty(searchResults.pages) && <Empty />}
              <SearchResultsContainer>
                {searchResults?.pages.map(searchResult => (
                  <li key={searchResult.id}>
                    <SearchResult
                      onClick={() => handleChooseElement(searchResult.id)}
                      onKeyDown={e => (e.key === 'Enter' ? handleChooseElement(searchResult.id) : undefined)}
                      tabIndex={0}
                    >
                      <SearchResultIcon
                        src={
                          searchResult.properties.category.pathPrimary
                            ? searchResult.properties.category.pathPrimary
                            : searchResult.properties.category.path
                        }
                        size={[32, 32]}
                      />
                      <Info>
                        <h3>
                          <Text weight={400} size={14} palette={{ primary: 100 }}>
                            {searchResult.properties.name}
                          </Text>
                        </h3>
                        <h4>
                          <Text weight={400} size={14} palette={{ light: 80 }}>
                            {searchResult.properties.subname}
                          </Text>
                        </h4>
                      </Info>
                    </SearchResult>
                  </li>
                ))}
                {isLoading ? <SearchResultLoader /> : hasNextPage && <InfiniteScrollTrigger ref={ref} />}
              </SearchResultsContainer>
            </Scrollbar>
          </SearchContainerItem>
          <ResultsRetractorButton onClick={handleClear}>
            <Text srOnly={true}>{t('labels.closeSearchResults')}</Text>
            <Icon src={arrowUpIcon} size={[16, 16]} />
          </ResultsRetractorButton>
        </>
      )}
    </>
  );
};
