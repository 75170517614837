import styled from 'styled-components';
import { breakpoints } from '@/settings/globalStyles/breakpoints';

export const LanguageSwitchContainer = styled.ul`
  z-index: 9;
  display: flex;
  position: absolute;
  right: 385px;
  bottom: 45px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: rgba(255,255,255, 0.4);
  border-radius: ${({ theme }) => theme.borderRadius[8]};

  @media (max-width: ${breakpoints.md}) {
    top: 145px;
    right: 15px;
    bottom: auto;
    flex-direction: column;
  }
`;

export const LanguageButton = styled.button<{active: boolean}>`
  width: 35px;
  height: 35px;
  background-color: ${({ theme, active }) => active ? 'transparent' : theme.palette.primary[100]};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  border: none;
  cursor: pointer;
`;
