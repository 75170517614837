import styled, { css } from 'styled-components';

import type { CSSProperties } from 'react';

interface BoxProps {
  mt?: CSSProperties['marginTop'];
  mr?: CSSProperties['marginRight'];
  mb?: CSSProperties['marginBottom'];
  ml?: CSSProperties['marginLeft'];
  pt?: CSSProperties['paddingTop'];
  pr?: CSSProperties['paddingRight'];
  pb?: CSSProperties['paddingBottom'];
  pl?: CSSProperties['paddingLeft'];
}

export const Box = styled.div<BoxProps>`
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px;
    `}
  ${({ mr }) =>
    mr &&
    css`
      margin-right: ${mr}px;
    `}
  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}px;
    `}
  ${({ ml }) =>
    ml &&
    css`
      margin-left: ${ml}px;
    `}
  ${({ pt }) =>
    pt &&
    css`
      padding-top: ${pt}px;
    `}
  ${({ pr }) =>
    pr &&
    css`
      padding-right: ${pr}px;
    `}
  ${({ pb }) =>
    pb &&
    css`
      padding-bottom: ${pb}px;
    `}
  ${({ pl }) =>
    pl &&
    css`
      padding-left: ${pl}px;
    `}
`;
