import { ChangeEventHandler, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/ui/Icon';
import { Text } from '@/ui/Text';
import { SrOnly } from '@/ui/SrOnly';
import { RoutingSearchResults } from './RoutingSearchResults';

import logoIcon from '@/assets/icons/logo.svg';
import closeIcon from '@/assets/icons/close.svg';
import settingsIcon from '@/assets/icons/settings.svg';
import shareIcon from '@/assets/icons/share.svg';
import startingPointDarkIcon from '@/assets/icons/startingPointDark.png';
import destinationPointDarkIcon from '@/assets/icons/destinationPointDark.png';
import aPointIcon from '@/assets/icons/aWaypoint.png';
import bPointIcon from '@/assets/icons/bWaypoint.png';
import arrowUpIcon from '@/assets/icons/arrowUp.svg';
import trashIcon from '@/assets/icons/trash.png';
import {
  ActionButton,
  ButtonContainer,
  InputsContainer,
  RoutingContainer,
  RoutingContainerItem,
  RoutingInput,
  RoutingInputContainer,
  RoutingInputIcon,
  RoutingWithResults,
  TitleContainer,
  TrashButtonContainer,
  CollapseButton,
  RouteDetailsContainer,
  RouteDetailsTitle,
  RouteDirectionList,
  RouteDirectionIcon,
  RouteDirectionItem,
} from './styles';
import { useSearchResultsQuery } from '../../queries';
import { useDebounce } from '@/hooks/useDebounce';
import { useRouteDispatch } from '../../Context/useRoute';
import { useRouteState } from '../../Context/useRoute';
import { useMapStateContext } from '../../Context/useMapContext';
import { useMapDispatchContext } from '../../Context/useMapContext';
import { toast } from 'react-hot-toast';
import { ShareModal } from '../ShareModal';
import { fromLonLat } from 'ol/proj';
import { OptionsModal } from './OptionsModal';
import { PointModal } from './PointModal';
import { Coords } from '../../types';
import qs from 'qs';

export const Routing = () => {
  const { t } = useTranslation();

  const [startValue, setStartValue] = useState('');
  const [openStartPoint, setOpenStartPoint] = useState(false);

  const [finishValue, setFinishValue] = useState('');
  const [openFinishPoint, setOpenFinishPoint] = useState(false);

  const [aValue, setAValue] = useState('');
  const [openAPoint, setOpenAPoint] = useState(false);

  const [bValue, setBValue] = useState('');
  const [openBPoint, setOpenBPoint] = useState(false);

  const [collapse, setCollapse] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);

  const debouncedStartValue = useDebounce(startValue, 500);
  const {
    data: startPointData,
    nextListPage: startPointNextListPage,
    isLoading: startPointIsLoading,
    isFetchingNextPage: startPointIsFetchingNextPage,
    hasNextPage: startPointHasNextPage,
  } = useSearchResultsQuery(debouncedStartValue);

  const debouncedFinishValue = useDebounce(finishValue, 500);
  const {
    data: finishPointData,
    nextListPage: finishPointNextListPage,
    isLoading: finishPointIsLoading,
    isFetchingNextPage: finishPointIsFetchingNextPage,
    hasNextPage: finishPointHasNextPage,
  } = useSearchResultsQuery(debouncedFinishValue);

  const debouncedAValue = useDebounce(aValue, 500);
  const {
    data: aPointData,
    nextListPage: aPointNextListPage,
    isLoading: aPointIsLoading,
    isFetchingNextPage: aPointIsFetchingNextPage,
    hasNextPage: aPointHasNextPage,
  } = useSearchResultsQuery(debouncedAValue);

  const debouncedBValue = useDebounce(bValue, 500);
  const {
    data: bPointData,
    nextListPage: bPointNextListPage,
    isLoading: bPointIsLoading,
    isFetchingNextPage: bPointIsFetchingNextPage,
    hasNextPage: bPointHasNextPage,
  } = useSearchResultsQuery(debouncedBValue);

  const {savePoint, setOpenRoutePanel, setWaypointVisibility} = useRouteDispatch();
  const {startingPoint, destinationPoint, aPoint, aPointVisible, bPoint, bPointVisible, openRoutePanel, route, pointFromMap} = useRouteState();
  const {map, geoObjects} = useMapStateContext();
  const {handleChangeMapProfile} = useMapDispatchContext();

  const refStartInput = useRef<HTMLInputElement>(null);
  const refFinishInput = useRef<HTMLInputElement>(null);
  const refAInput = useRef<HTMLInputElement>(null);
  const refBInput = useRef<HTMLInputElement>(null);

  const handleChangeStartPoint: ChangeEventHandler<HTMLInputElement> = e => {
    setStartValue(e.target.value);
    setOpenStartPoint(true);
    setOpenAPoint(false);
    setOpenBPoint(false);
    setOpenFinishPoint(false);
  };

  const handleChangeFinishPoint: ChangeEventHandler<HTMLInputElement> = e => {
    setFinishValue(e.target.value);
    setOpenFinishPoint(true);
    setOpenStartPoint(false);
    setOpenAPoint(false);
    setOpenBPoint(false);
  };

  const handleChangeAPoint: ChangeEventHandler<HTMLInputElement> = e => {
    setAValue(e.target.value);
    setOpenAPoint(true);
    setOpenStartPoint(false);
    setOpenBPoint(false);
    setOpenFinishPoint(false);
  };

  const handleChangeBPoint: ChangeEventHandler<HTMLInputElement> = e => {
    setBValue(e.target.value);
    setOpenBPoint(true);
    setOpenStartPoint(false);
    setOpenAPoint(false);
    setOpenFinishPoint(false);
  };

  const handleClose = () => {
    savePoint('startingPoint', undefined);
    setStartValue('');

    savePoint('destinationPoint', undefined);
    setFinishValue('');

    savePoint('aPoint', undefined);
    setAValue('');
    setWaypointVisibility('aPoint', false);

    savePoint('bPoint', undefined);
    setBValue('');
    setWaypointVisibility('bPoint', false);

    setOpenRoutePanel(false);  

    if (window.location.search.includes('route')) {
      window.history.pushState({}, document.title, window.location.origin);
    }
  };

  const handleChooseStartPoint = (id: number) => {
    const start = geoObjects.find((geoObject) => geoObject.id === id);

    if (id === aPoint?.id || id === bPoint?.id || id === destinationPoint?.id) {
      toast.error(t('route.pointAlreadyOnRoute'));
      return;
    }
    
    savePoint('startingPoint', start);
    if (start) {
      setStartValue(start.properties.name);
    }
    setOpenStartPoint(false);
  };

  const handleChooseFinishPoint = (id: number) => {
    const destination = geoObjects.find((geoObject) => geoObject.id === id);

    if (id === startingPoint?.id || id === aPoint?.id || id === bPoint?.id) {
      toast.error(t('route.pointAlreadyOnRoute'));
      return;
    }
    
    savePoint('destinationPoint', destination);
    if (destination) {
      setFinishValue(destination.properties.name);
    }
    setOpenFinishPoint(false);
  };

  const handleChooseAPoint = (id: number) => {
    const a = geoObjects.find((geoObject) => geoObject.id === id);

    if (id === startingPoint?.id || id === bPoint?.id || id === destinationPoint?.id) {
      toast.error(t('route.pointAlreadyOnRoute'));
      return;
    }
    
    savePoint('aPoint', a);
    if (a) {
      setAValue(a.properties.name);
    }
    setOpenAPoint(false);
  };

  const handleChooseBPoint = (id: number) => {
    const b = geoObjects.find((geoObject) => geoObject.id === id);

    if (id === startingPoint?.id || id === aPoint?.id || id === destinationPoint?.id) {
      toast.error(t('route.pointAlreadyOnRoute'));
      return;
    }
    
    savePoint('bPoint', b);
    if (b) {
      setBValue(b.properties.name);
    }
    setOpenBPoint(false);
  };

  const handleOnBlur = (inputType: 'start' | 'finish' | 'a' | 'b') => {
    if (inputType === 'start') {
      setTimeout(() => {
        setOpenStartPoint(false);
      }, 500);
    } else if (inputType === 'finish') {
      setTimeout(() => {
        setOpenFinishPoint(false);
      }, 500);
    } else if (inputType === 'a') {
      setTimeout(() => {
        setOpenAPoint(false);
      }, 500);
    } else if (inputType === 'b') {
      setTimeout(() => {
        setOpenBPoint(false);
      }, 500);
    }
  };

  const handleDirection = (profile: number, center: [number, number]) => {
    const view = map?.getView();

    view?.cancelAnimations();
    handleChangeMapProfile(profile);
    view?.animate({
      zoom: 21,
      center: fromLonLat(center),
    });
  };

  const aPointRemove = () => {
    if (!bPointVisible) {
      savePoint('aPoint', undefined);
      setAValue('');
      setWaypointVisibility('aPoint', false);
    } else {
      savePoint('aPoint', bPoint);
      setAValue(bValue);
      bPointRemove();
    }
  };

  const bPointRemove = () => {
    savePoint('bPoint', undefined);
    setBValue('');
    setWaypointVisibility('bPoint', false);
  };

  useEffect(() => {
    if (!openStartPoint) {
      const startingPointName = geoObjects.find((geoObject) => geoObject.id === startingPoint?.id)?.properties.name;
      setStartValue(startingPointName ? startingPointName : '');
      setOpenStartPoint(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openStartPoint]);

  useEffect(() => {
    if (!openFinishPoint) {
      const destinationPointName = geoObjects.find((geoObject) => geoObject.id === destinationPoint?.id)?.properties.name;
      setFinishValue(destinationPointName ? destinationPointName : '');
      setOpenFinishPoint(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFinishPoint]);

  useEffect(() => {
    if (!openAPoint) {
      const aPointName = geoObjects.find((geoObject) => geoObject.id === aPoint?.id)?.properties.name;
      setAValue(aPointName ? aPointName : '');
      setOpenAPoint(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAPoint]);

  useEffect(() => {
    if (!openBPoint) {
      const bPointName = geoObjects.find((geoObject) => geoObject.id === bPoint?.id)?.properties.name;
      setBValue(bPointName ? bPointName : '');
      setOpenBPoint(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBPoint]);

  useEffect(() => {
    const startingPointName = geoObjects.find((geoObject) => geoObject.id === startingPoint?.id)?.properties.name;
    const destinationPointName = geoObjects.find((geoObject) => geoObject.id === destinationPoint?.id)?.properties.name;
    const aPointName = geoObjects.find((geoObject) => geoObject.id === aPoint?.id)?.properties.name;
    const bPointName = geoObjects.find((geoObject) => geoObject.id === bPoint?.id)?.properties.name;

    if (startingPointName) {
      setStartValue(startingPointName);
    } else if (startingPoint?.keyName) {
      setStartValue(t(`map.customMapObjectNames.${startingPoint.keyName}`));
    }

    if (destinationPointName) {
      setFinishValue(destinationPointName);
    } else if (destinationPoint?.keyName) {
      setFinishValue(t(`map.customMapObjectNames.${destinationPoint.keyName}`));
    }

    if (aPointName) {
      setAValue(aPointName);
    } else if (aPoint?.keyName) {
      setAValue(t(`map.customMapObjectNames.${aPoint.keyName}`));
    }

    if (bPointName) {
      setBValue(bPointName);
    } else if (bPoint?.keyName) {
      setBValue(t(`map.customMapObjectNames.${bPoint.keyName}`));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoObjects, pointFromMap]);

  useEffect(() => {
    if (openRoutePanel && startingPoint) {
      setStartValue(startingPoint.properties.name);
    }

    if (openRoutePanel && destinationPoint) {
      setFinishValue(destinationPoint.properties.name);
    }

    if (openRoutePanel && aPoint) {
      setAValue(aPoint.properties.name);
      setWaypointVisibility('aPoint', true);
    }

    if (openRoutePanel && bPoint) {
      setBValue(bPoint.properties.name);
      setWaypointVisibility('bPoint', true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRoutePanel]);

  return (
    <>
      {
        openRoutePanel && (
          <RoutingContainer collapse={collapse}>
            <RoutingContainerItem collapse={collapse}>
              <Icon src={logoIcon} size={[74, 26]} />
              <TitleContainer>
                <Text weight={600} size={16} palette={{ primary: 100 }} transform='uppercase'>
                  {t('map.controls.routingTitle')}
                </Text>
              </TitleContainer>
              <ActionButton mode='flat' onClick={handleClose}>
                <Text srOnly={true}>{t('labels.closeRouteMode')}</Text>
                <Icon src={closeIcon} size={[32, 32]} />
              </ActionButton>
            </RoutingContainerItem>
            <RoutingContainerItem collapse={collapse}>
              <InputsContainer>
                <RoutingWithResults>
                  <RoutingInputContainer onClick={() => refStartInput.current?.focus()}>
                    <RoutingInputIcon src={startingPointDarkIcon} size={[20, 20]} />
                    <SrOnly>
                      <label htmlFor='startInput'>{t('labels.startingPoint')}</label>
                    </SrOnly>
                    <RoutingInput
                      ref={refStartInput}
                      id='startInput'
                      type='text'
                      onChange={handleChangeStartPoint}
                      onBlur={() => handleOnBlur('start')}
                      placeholder={t('map.controls.startPoint')}
                      value={startValue}
                    />
                  </RoutingInputContainer>
                  {startValue && openStartPoint && (
                    <RoutingSearchResults
                      searchResults={startPointData}
                      isLoading={startPointIsLoading || startPointIsFetchingNextPage}
                      hasNextPage={startPointHasNextPage}
                      nextListPage={startPointNextListPage}
                      handleChooseElement={handleChooseStartPoint}
                    />
                  )}
                </RoutingWithResults>
                {
                  aPointVisible &&
                  <RoutingWithResults>
                    <RoutingInputContainer onClick={() => refAInput.current?.focus()}>
                      <RoutingInputIcon src={aPointIcon} size={[20, 20]} />
                      <SrOnly>
                        <label htmlFor='aInput'>{t('labels.waypoint')}</label>
                      </SrOnly>
                      <RoutingInput
                        ref={refAInput}
                        id='aInput'
                        type='text'
                        onChange={handleChangeAPoint}
                        onBlur={() => handleOnBlur('a')}
                        placeholder={t('map.controls.waypoint')}
                        value={aValue}
                      />
                    </RoutingInputContainer>
                    {aValue && openAPoint && (
                      <RoutingSearchResults
                        searchResults={aPointData}
                        isLoading={aPointIsLoading || aPointIsFetchingNextPage}
                        hasNextPage={aPointHasNextPage}
                        nextListPage={aPointNextListPage}
                        handleChooseElement={handleChooseAPoint}
                      />
                    )}
                    <TrashButtonContainer>
                      <ActionButton mode='flat' onClick={aPointRemove}>
                        <Text srOnly={true}>{t('labels.deleteWaypoint')}</Text>
                        <Icon src={trashIcon} size={[24, 24]} />
                      </ActionButton>
                    </TrashButtonContainer>
                  </RoutingWithResults>
                }
                {
                  bPointVisible &&
                  <RoutingWithResults>
                    <RoutingInputContainer onClick={() => refBInput.current?.focus()}>
                      <RoutingInputIcon src={bPointIcon} size={[20, 20]} />
                      <SrOnly>
                        <label htmlFor='bInput'>{t('labels.waypoint')}</label>
                      </SrOnly>
                      <RoutingInput
                        ref={refBInput}
                        id='bInput'
                        type='text'
                        onChange={handleChangeBPoint}
                        onBlur={() => handleOnBlur('b')}
                        placeholder={t('map.controls.waypoint')}
                        value={bValue}
                      />
                    </RoutingInputContainer>
                    {bValue && openBPoint && (
                      <RoutingSearchResults
                        searchResults={bPointData}
                        isLoading={bPointIsLoading || bPointIsFetchingNextPage}
                        hasNextPage={bPointHasNextPage}
                        nextListPage={bPointNextListPage}
                        handleChooseElement={handleChooseBPoint}
                      />
                    )}
                    <TrashButtonContainer>
                      <ActionButton mode='flat' onClick={bPointRemove}>
                        <Text srOnly={true}>{t('labels.deleteWaypoint')}</Text>
                        <Icon src={trashIcon} size={[24, 24]} />
                      </ActionButton>
                    </TrashButtonContainer>
                  </RoutingWithResults>
                }
                <RoutingWithResults>
                  <RoutingInputContainer onClick={() => refFinishInput.current?.focus()}>
                    <RoutingInputIcon src={destinationPointDarkIcon} size={[20, 20]} />
                    <SrOnly>
                      <label htmlFor='finishInput'>{t('labels.destinationPoint')}</label>
                    </SrOnly>
                    <RoutingInput
                      ref={refFinishInput}
                      id='finishInput'
                      type='text'
                      onChange={handleChangeFinishPoint}
                      onBlur={() => handleOnBlur('finish')}
                      placeholder={t('map.controls.finishPoint')}
                      value={finishValue}
                    />
                  </RoutingInputContainer>
                  {finishValue && openFinishPoint && (
                    <RoutingSearchResults
                      searchResults={finishPointData}
                      isLoading={finishPointIsLoading || finishPointIsFetchingNextPage}
                      hasNextPage={finishPointHasNextPage}
                      nextListPage={finishPointNextListPage}
                      handleChooseElement={handleChooseFinishPoint}
                    />
                  )}
                </RoutingWithResults>
              </InputsContainer>
            </RoutingContainerItem>
            <ButtonContainer collapse={collapse}>
              <ActionButton mode='secondary' onClick={() => setOpenOptions(true)}>
                <Text srOnly={true}>{t('labels.routeOptions')}</Text>
                <Icon src={settingsIcon} size={[28, 28]} />
              </ActionButton>
              {
                route &&
                (startingPoint?.keyName === 'mapPoint' || !startingPoint?.keyName) &&
                (destinationPoint?.keyName === 'mapPoint' || !destinationPoint?.keyName ) &&
                (aPoint?.keyName === 'mapPoint' || !aPoint?.keyName) &&
                (bPoint?.keyName === 'mapPoint' || !bPoint?.keyName) && (
                  <ActionButton mode='primary' onClick={() => setOpenModal(true)}>
                    <Text srOnly={true}>{t('labels.shareRoute')}</Text>
                    <Icon src={shareIcon} size={[24, 24]} />
                  </ActionButton>
                )
              }
            </ButtonContainer>
            {
              route && (
                <RouteDetailsContainer collapse={collapse}>
                  <RouteDetailsTitle>
                    <Text weight={600} size={15} palette={{ primary: 100 }} transform='uppercase'>
                      {t('route.routeDetails')}
                    </Text>
                  </RouteDetailsTitle>
                  <RouteDirectionList>
                    {
                      route.map((routeNode) => (
                        <li key={routeNode.id}>
                          <RouteDirectionItem onClick={() => handleDirection(routeNode.p, [routeNode.x, routeNode.y])}>
                            <RouteDirectionIcon src={routeNode.icon} size={[22, 22]} />
                            <Text weight={400} size={12} palette={{ light: 500 }}>
                              {routeNode.t}
                            </Text>
                          </RouteDirectionItem>
                        </li>
                      ))
                    }
                  </RouteDirectionList>
                </RouteDetailsContainer>
              )
            }
            <CollapseButton onClick={() => setCollapse(!collapse)} collapse={collapse}>
              <Icon src={arrowUpIcon} size={[16, 16]} />
            </CollapseButton>
          </RoutingContainer>
        )
      }
      <ShareModal
        open={openModal}
        setClose={() => setOpenModal(false)}
        name={t('labels.shareRouteModalName')}
        sharedLink={`${window.location.origin}/?${qs.stringify({
          route: {
            startingPoint: startingPoint?.keyName === 'mapPoint' ? {type: 'POINT_FROM_MAP', keyName: 'mapPoint', coords: new Coords(startingPoint.naviPoint), profile: startingPoint.properties.profile} : startingPoint?.id,
            destinationPoint: destinationPoint?.keyName === 'mapPoint' ? {type: 'POINT_FROM_MAP', keyName: 'mapPoint', coords: new Coords(destinationPoint.naviPoint), profile: destinationPoint.properties.profile} : destinationPoint?.id,
            ...(aPoint?.id && {aPoint: aPoint?.keyName === 'mapPoint' ? {type: 'POINT_FROM_MAP', keyName: 'mapPoint', coords: new Coords(aPoint.naviPoint), profile: aPoint.properties.profile} : aPoint?.id}),
            ...(bPoint?.id && {bPoint: bPoint?.keyName === 'mapPoint' ? {type: 'POINT_FROM_MAP', keyName: 'mapPoint', coords: new Coords(bPoint.naviPoint), profile: bPoint.properties.profile} : bPoint?.id})}
        })}`}
      />
      <OptionsModal
        open={openOptions}
        setClose={() => setOpenOptions(false)}
      />
      <PointModal />
    </>
  );
};
