import type { IProfile } from '../../types';

import { useMapDispatchContext } from '../../Context/useMapContext';

import { Text } from '@/ui/Text';
import { Item } from './styles';
import { useTranslation } from 'react-i18next';

interface ProfilesItemProps {
  profile: IProfile;
  active: boolean;
  disabled: boolean;
}

export const ProfilesItem = ({ profile, active, disabled }: ProfilesItemProps) => {
  const {t} = useTranslation();
  const { handleChangeMapProfile } = useMapDispatchContext();

  const onClick = () => {
    handleChangeMapProfile(profile.key);
  };

  return (
    <li>
      <Item active={active} disabled={disabled} onClick={onClick}>
        <Text srOnly={true}>{active ? `${t('labels.currentlyActiveFloor')}: ` : `${t('labels.floor')}: `}</Text>
        <Text weight={700} size={18} palette={active ? { light: 0 } : { primary: 100 }}>
          {profile.label}
        </Text>
      </Item>
    </li>
  );
};
