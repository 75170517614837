import styled from 'styled-components';
import { breakpoints } from '@/settings/globalStyles/breakpoints';
import SimpleBar from 'simplebar-react';

interface ItemProps {
  active: boolean;
  disabled: boolean;
}

export const Item = styled.button<ItemProps>`
  z-index: 10;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ theme, active }) => (active ? theme.palette.secondary['100'] : theme.palette.light['0'])};
  cursor: pointer;
  opacity: ${({disabled}) => (disabled ? '0.2' : '1')};
  border: none;

  :hover {
    background-color: ${({ theme, active }) => (active ? theme.palette.secondary['100'] : theme.palette.secondary['200'])};
  }
  
  &:focus-visible {
    outline-offset: -2px;
  }
`;

export const Container = styled.div`
  z-index: 10;
  position: absolute;
  right: 25px;
  bottom: 130px;
  cursor: pointer;

  @media (max-width: ${breakpoints.md}) {
    right: 15px;
    bottom: 100px;
  }
`;

export const ProfileListContainer = styled(SimpleBar)`
  z-index: 10;
  position: absolute;
  right: 25px;
  bottom: 195px;
  width: 55px;
  max-height: 200px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.light['0']};
  border-radius: ${({ theme }) => theme.borderRadius['12']};

  display: flex;
  align-items: center;

  max-height: calc(100% - 165px - 140px);
  overflow: hidden;
  overflow-y: auto;

  @media (max-width: ${breakpoints.md}) {
    right: 15px;
    bottom: 165px;
  }
`;

export const ProfileList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
