import React from 'react';
import { IconView } from './styles';

interface IconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: [number, number];
}

export const Icon = (props: IconProps): JSX.Element => {
  return <IconView alt="" {...props} />;
}
