import React from 'react';
import { IProfile } from '@/pages/Map/types';
import { useMapStateContext } from '@/pages/Map/Context/useMapContext';
import { Feature, Polygon } from '@turf/helpers';
import { mapExtentToPolygonBbox } from '@/pages/Map/utils';
import centerOfMass from '@turf/center-of-mass';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

export const useAvailableProfiles = (allProfiles: IProfile[] | undefined) => {
  const allProfileKeys = React.useMemo(() => allProfiles?.map((profile) => profile.key), [allProfiles]);
  const { map, zoom,  selectedObject, buildings } = useMapStateContext();
  const [bbox, setBbox] = React.useState<Feature<Polygon>>();
  const [availableProfiles, setAvailableProfiles] = React.useState(allProfileKeys);

  const onMoveEnd = React.useCallback(() => {
    if (map) {
      const extent = map.getView().calculateExtent();
      setBbox(mapExtentToPolygonBbox(extent));
    } 
  }, [map]);

  React.useEffect(() => {
    map?.on('moveend', onMoveEnd);
  }, [map, onMoveEnd]);

  React.useEffect(() => {
    if (zoom && Math.round(zoom) <= 17) {
      setAvailableProfiles(allProfileKeys);
    } else if (selectedObject && selectedObject.properties.availableProfiles) {
      setAvailableProfiles(selectedObject.properties.availableProfiles)
    } else if (zoom && Math.round(zoom) >= 20 && buildings) {
      const currentMapCenter = centerOfMass(bbox);
      let currentMapCenterInBuilding = false;

      for (const building of buildings) {
        if (booleanPointInPolygon(currentMapCenter, building.geometry)) {
          setAvailableProfiles(building.availableProfiles);
          currentMapCenterInBuilding = true;
          break;
        }
      }

      if (!currentMapCenterInBuilding) {
        setAvailableProfiles(allProfileKeys);
      }
    } else if (allProfileKeys) {
      setAvailableProfiles(allProfileKeys);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbox, selectedObject, allProfileKeys, buildings]);

  return {availableProfiles} as const;
};
