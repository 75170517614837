import { Text } from '@/ui/Text';
import { useMapDispatchContext, useMapStateContext } from '../../Context/useMapContext';
import { useTranslation } from 'react-i18next';
import { Scrollbar, CategoriesContainer, CategoriesItem } from './styles';

export const Categories = (): JSX.Element => {
  const {t} = useTranslation();
  const { geoCategories, selectedCategory } = useMapStateContext();
  const { handleSelectedCategory } = useMapDispatchContext();

  return (
    <Scrollbar>
        <CategoriesContainer>
          {geoCategories.map(geoCategory => {
            const selected = selectedCategory?.id === geoCategory.id;

            return (
              geoCategory.isVisibleInCategoryBar && (
                <li key={geoCategory.id}>
                  <CategoriesItem selected={selected} onClick={() => handleSelectedCategory(geoCategory)} aria-selected={selected}>
                    <Text srOnly={true}>{t('labels.filterMapByCategory')}: </Text>
                    <Text weight={400} size={14} palette={selected ? { light: 0 } : { primary: 100 }}>
                      {geoCategory.name}
                    </Text>
                  </CategoriesItem>
                </li>
              )
            );
          })}
        </CategoriesContainer>
    </Scrollbar>
  );
};
