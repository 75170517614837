import { createGlobalStyle } from 'styled-components';
import 'simplebar-react/dist/simplebar.min.css';

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 10px;
    min-height: 100vh;
  }

  body {
    margin: 0;
    min-height: 100vh;
    overflow-y: hidden;
    font-family: ${props => props.theme.typography.fontFamily};
  }

  * {
    &:focus-visible {
      outline: 2px solid #000000;
      outline-offset: 2px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  input {
    font-family: ${props => props.theme.typography.fontFamily};
  
    ::placeholder {
      font-family: ${({ theme }) => theme.typography.fontFamily};
   }
  }
`;
