import React from 'react';

import { Text } from '../Text';

import { ButtonVariants, ShadowContainer, Badge } from './styles';

type MapButtonControlProps =
  | {
      variant: Extract<ButtonVariants, 'DEFAULT' | 'ACTIVE'>;
      icon: React.ReactNode;
      onClick: () => void;
    } & React.ButtonHTMLAttributes<HTMLButtonElement>
  | {
      variant: Extract<ButtonVariants, 'BADGE'>;
      badge: string;
      icon: React.ReactNode;
      onClick: () => void;
    } & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const MapButtonControl = React.memo((props: MapButtonControlProps) => {
  const { variant, icon, onClick, ...htmlButtonProps } = props;

  return (
    <ShadowContainer variant={variant} onClick={onClick} {...htmlButtonProps}>
      {icon}
      {variant === 'BADGE' && (
        <Badge>
          <Text weight={700} size={12} palette={{ light: 0 }} aria-hidden="true">
            {props.badge}
          </Text>
        </Badge>
      )}
    </ShadowContainer>
  );
});
