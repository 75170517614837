import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteState, useRouteDispatch } from '../../Context/useRoute';
import { Text } from '@/ui/Text';
import { Icon } from '@/ui/Icon';
import { Box } from '@/ui/Box';
import Modal from 'react-modal';
import {
  modalCustomStyles,
  ModalContentContainer,
  CloseModalButton,
  ModalButton,
  ModalButtonIcon,
} from './styles';
import closeIcon from '@/assets/icons/close.svg';
import addWaypointIcon from '@/assets/icons/addWaypoint.png';
import startingPointIcon from '@/assets/icons/startingPointDark.png';
import destinationPointIcon from '@/assets/icons/destinationPointDark.png';

export const PointModal = React.memo(() => {
  const {t} = useTranslation();
  const {pointFromMap, aPoint, bPoint} = useRouteState();
  const {setPointFromMap, savePoint, setWaypointVisibility} = useRouteDispatch();

  const closeModal = React.useCallback(() => {
    setPointFromMap(undefined);
  }, [setPointFromMap]);

  const selectPointType = React.useCallback((pointType: Parameters<typeof savePoint>[0]) => {
    if (pointType === 'aPoint' || pointType === 'bPoint') {
      if ((aPoint && bPoint) || !aPoint) {
        savePoint('aPoint', pointFromMap);
        setWaypointVisibility('aPoint', true);
      } else if (!bPoint) {
        savePoint('bPoint', pointFromMap);
        setWaypointVisibility('bPoint', true);
      }
    } else {
      savePoint(pointType, pointFromMap);
    }
    closeModal();
  }, [pointFromMap, aPoint, bPoint, savePoint, closeModal, setWaypointVisibility]);

  return (
    <Modal
      isOpen={!!pointFromMap}
      onRequestClose={closeModal}
      style={modalCustomStyles}
    >
      <ModalContentContainer>
        <CloseModalButton onClick={closeModal}>
          <Text srOnly={true}>{t('labels.closeAddPointFromMap')}</Text>
          <Icon src={closeIcon} size={[32, 32]} />
        </CloseModalButton>
        <Box mb={35}>
          <h3>
            <Text size={20} weight={600} palette={{primary: 100}} transform="uppercase">
              {t('labels.markAs')}
            </Text>
          </h3>
        </Box>
        <ModalButton onClick={() => selectPointType('startingPoint')}>
          <ModalButtonIcon size={[24, 24]} src={startingPointIcon} />
          <Text weight={400} size={13} palette={{ primary: 200 }}>
            {t('map.controls.startPoint')}
          </Text>
        </ModalButton>
        <ModalButton onClick={() => selectPointType('aPoint')}>
          <ModalButtonIcon size={[24, 24]} src={addWaypointIcon} />
          <Text weight={400} size={13} palette={{ primary: 200 }}>
            {t('map.controls.waypoint')}
          </Text>
        </ModalButton>
        <ModalButton onClick={() => selectPointType('destinationPoint')}>
          <ModalButtonIcon size={[24, 24]} src={destinationPointIcon} />
          <Text weight={400} size={13} palette={{ primary: 200 }}>
            {t('map.controls.finishPoint')}
          </Text>
        </ModalButton>
      </ModalContentContainer>
    </Modal>
  )
});
