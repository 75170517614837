import { useEffect } from 'react';
import { Vector as VectorLayerOl } from 'ol/layer';

import { useMapStateContext } from '../Context/useMapContext';

interface VectorLayerProps {
  zIndex: number;
}

export const VectorLayer = ({ zIndex }: VectorLayerProps) => {
  const { map, vectorSource } = useMapStateContext();

  useEffect(() => {
    if (!map) {
      return;
    }

    const vectorLayer = new VectorLayerOl({
      source: vectorSource,
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, vectorSource, zIndex]);

  return null;
};
