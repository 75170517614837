import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { breakpoints } from '@/settings/globalStyles/breakpoints';

export const Scrollbar = styled(SimpleBar)`
  width: 100%;
  max-width: calc(100% - 475px);
  z-index: 9;
  position: absolute;
  left: 475px;
  top: 28px;
  padding-bottom: 10px;
  overflow-y: hidden;
  overflow-x: auto;

  @media (max-width: ${breakpoints.md}) {
    max-width: 100%;
    top: 90px;
    left: 0;
  }
`;

export const CategoriesContainer = styled.ul`
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0.4rem;
  padding-left: 20px;
  list-style-type: none;

  @media (max-width: ${breakpoints.md}) {
    padding-left: 15px;
  }
`;

export const CategoryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const CategoriesItem = styled.button<{ selected: boolean }>`
  background-color: ${({ theme, selected }) => (selected ? theme.palette.primary[100] : theme.palette.light[0])};
  border-radius: ${({ theme }) => theme.borderRadius[20]};
  padding: 1.6rem 2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: none;
  box-shadow: ${({theme}) => `0 0 20px 0 ${theme.palette.opacity[50]}`};
  cursor: pointer;
  user-select: none;
`;
