import { useCallback, useEffect, useState } from 'react';
import { Select as SelectOl } from 'ol/interaction';
import { SelectEvent } from 'ol/interaction/Select';
import BaseEvent from 'ol/events/Event';
import { toLonLat } from 'ol/proj';
import { useRouteState, useRouteDispatch } from '../Context/useRoute';

import { useMapDispatchContext, useMapStateContext } from '../Context/useMapContext';
import { getFeatureSelectStyle } from '../utils';
import { IGeoObject } from '../types';
import i18n from '@/settings/i18n/i18n';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import Feature, { FeatureLike } from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { MapBrowserEvent } from 'ol';

export const SelectInteraction = () => {
  const { map, geoObjects, mapProfile, buildings } = useMapStateContext();
  const { handleSelectObject } = useMapDispatchContext();
  const { openRoutePanel } = useRouteState();
  const { setPointFromMap } = useRouteDispatch();

  const [clickedPoint, setClickedPoint] = useState<FeatureLike | Coordinate>();

  const handleSelect = useCallback(
    (e: BaseEvent | Event) => {
      if (!(e instanceof SelectEvent)) {
        handleSelectObject(null, 'map');
        return;
      }
      const selectedFeature = e.selected.at(0);

      if (!selectedFeature) {
        handleSelectObject(null, 'map');
        return;
      }

      handleSelectObject(selectedFeature.getId(), 'map');
    },
    [handleSelectObject],
  );

  const addPointFromMap = useCallback(() => {
    if (!openRoutePanel) {
      return;
    }

    if (clickedPoint instanceof Feature) {
      setPointFromMap(geoObjects.find(geoObject => geoObject.id === clickedPoint.getId()));
    } else if (Array.isArray(clickedPoint)) {
      setPointFromMap(new IGeoObject({
        type: 'POINT_FROM_MAP',
        keyName: 'mapPoint',
        coords: {latitude: clickedPoint[1].toString(), longitude: clickedPoint[0].toString()},
        profile: buildings.find((building) => booleanPointInPolygon(clickedPoint, building.geometry)) ? mapProfile : '0',
      },
      i18n.t('map.customMapObjectNames.mapPoint')
    ));
    }
  }, [geoObjects, mapProfile, buildings, openRoutePanel, clickedPoint, setPointFromMap]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const selectInteraction = map
      .getInteractions()
      .getArray()
      .find(interaction => interaction instanceof SelectOl) as SelectOl | undefined;

    if (selectInteraction) {
      return;
    }

    const select = new SelectOl({
      style: getFeatureSelectStyle(),
      filter: feature => !feature.getProperties().icon,
    });

    map.addInteraction(select);
    select.on(['select'], handleSelect);

  }, [map, handleSelect]);

  useEffect(() => {
    if (map) {
      window.addEventListener('contextmenu', (e) => {
        e.preventDefault();
        map.dispatchEvent(new MapBrowserEvent('singleclick', map, e));
      });
    }
  }, [map]);

  useEffect(() => {
    map?.on('singleclick', (e) => {
      if (e.frameState) {
        return;
      }
      
      const feature = map?.forEachFeatureAtPixel(e.pixel, (feature) => {
        return feature;
      });

      setClickedPoint(feature ? feature : toLonLat(e.coordinate));
    });
  }, [map]);

  useEffect(() => {
    addPointFromMap();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedPoint]);

  return null;
};
