import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/ui/Text';
import { Icon } from '@/ui/Icon';
import { Box } from '@/ui/Box';

import closeIcon from '@/assets/icons/close.svg';
import {
  modalCustomStyles,
  ModalContentContainer,
  CloseModalButton,
  ModalInputContainer,
  ModalInput,
  ModalButton,
} from './styles';
import Modal from 'react-modal';
import { toast } from 'react-hot-toast';

interface ShareModalProps {
  open: boolean;
  setClose: () => void;
  name: string;
  sharedLink: string;
}

export const ShareModal = React.memo(({open, setClose, name, sharedLink}: ShareModalProps) => {
  const { t } = useTranslation();

  const copySharedLink = React.useCallback(() => {
    navigator.clipboard.writeText(sharedLink).then(() => {
      toast.success(t('labels.copiedToClipboard'));
    });
  }, [sharedLink, t]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={setClose}
      style={modalCustomStyles}
      contentLabel={`${t('labels.share')} ${name}`}
    >
      <ModalContentContainer>
        <CloseModalButton onClick={setClose}>
          <Text srOnly={true}>{t('labels.closeModalSharing')}</Text>
          <Icon src={closeIcon} size={[32, 32]} />
        </CloseModalButton>
        <h3>
          <Text size={20} weight={600} palette={{primary: 100}} transform="uppercase">
            {t('labels.share')}
          </Text>
          <Text srOnly={true}> {name}</Text>
        </h3>
        <Box mt={35} mb={15}>
          <label htmlFor="linkToShare">
            <Text size={13} weight={400} palette={{primary: 100}} transform="uppercase">
              {t('labels.linkToShare')}
            </Text>
          </label>
        </Box>
        <ModalInputContainer>
          <ModalInput id="linkToShare" type="text" value={sharedLink} readOnly />
          <ModalButton onClick={copySharedLink}>
            <Text size={12} weight={500} palette={{light: 0}} transform="uppercase">{t('labels.copyLink')}</Text>
          </ModalButton>
        </ModalInputContainer>
      </ModalContentContainer>
    </Modal>
  );
});
