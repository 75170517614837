import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { breakpoints } from '@/settings/globalStyles/breakpoints';

export const AsideInformationContainer = styled(SimpleBar)`
  z-index: 15;
  position: absolute;
  left: 15px;
  top: 87px;
  max-height: 75vh;
  width: 460px;
  color: ${({ theme }) => theme.palette.light[0]};

  gap: 1.6rem;
  padding: 1.6rem;
  border-radius: ${({ theme }) => theme.borderRadius[12]};
  background-color: ${({ theme }) => theme.palette.primary['100']};
  overflow-y: auto;
  box-sizing: border-box;

  .simplebar-scrollbar::before {
    background-color: ${({ theme }) => theme.palette.light[0]};
  }

  @media (max-width: ${breakpoints.md}) {
    width: auto;
    right: 15px;
  }
`;

export const MainInformation = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  align-items: flex-start;
`;

export const Location = styled.h3`
  display: flex;
  gap: 1rem;
`;

export const Category = styled.h3`
  display: flex;
  gap: 1rem;
  align-items: center;

  img {
    padding-bottom: 1px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
`;

export const ActionButton = styled.button`
  background-color: ${({ theme }) => theme.palette.light[0]};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  border: none;
  padding: 0.8rem;
  cursor: pointer;
  outline-color: #FFFFFF;
`;

export const BuildingPhoto = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius[10]};
`;

export const CollapseButton = styled.button<{collapse: boolean}>`
  display: none;

  @media (max-width: ${breakpoints.md}) {
    display: block;
    width: 100%;
    margin-top: 15px;
    transform: ${({collapse}) => collapse ? 'rotate(180deg)' : 'rotate(0)'};
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`;

export const DescriptionContainer = styled.div<{collapse: boolean}>`
  display: block;

  @media (max-width: ${breakpoints.md}) {
    display: ${({collapse}) => collapse ? 'none' : 'block'};
  }
`;
