import { Icon } from '@/ui/Icon';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { breakpoints } from '@/settings/globalStyles/breakpoints';

export const SearchContainer = styled.div`
  z-index: 10;
  width: 460px;
  box-sizing: border-box;
  position: absolute;
  left: 15px;
  top: 15px;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  background-color: ${({ theme }) => theme.palette.light['0']};
  padding: 1.6rem;
  border-radius: ${({ theme }) => theme.borderRadius[12]};

  @media (max-width: ${breakpoints.md}) {
    width: auto;
    right: 15px;
  }
`;

export const SearchContainerItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const SearchInputContainer = styled.div`
  position: relative;

  display: flex;
  flex-grow: 1;
  align-items: center;

  background-color: ${({ theme }) => theme.palette.light[60]};
  border-radius: ${({ theme }) => theme.borderRadius[20]};

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export const SearchInput = styled.input`
  height: 35px;
  flex-grow: 1;
  padding: 0 3rem 0 4rem;

  background-color: ${({ theme }) => theme.palette.light[60]};
  border-radius: ${({ theme }) => theme.borderRadius[20]};
  border: none;
  width: 100%;
`;

export const SearchInputIcon = styled(Icon)`
  position: absolute;
  padding-left: 1rem;
`;

export const SearchClear = styled.button`
  position: absolute;
  right: 0.4rem;

  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  user-select: none;
`;

export const ActionButton = styled.button<{ closeMode: boolean }>`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, closeMode }) => (closeMode ? theme.palette.light[0] : theme.palette.primary[100])};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  border: none;
  cursor: pointer;
`;

export const Scrollbar = styled(SimpleBar)`
  width: 430px;
  max-height: 70vh;
  padding-right: 20px;

  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export const SearchResultsContainer = styled.ul`
  list-style-type: none;
  margin: 1rem 0 0 0;
  padding: 0;
`;

export const SearchResult = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 0.8rem;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.palette.light[75]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.light[50]};
    border-radius: ${({ theme }) => theme.borderRadius[8]};
  }

  &:focus-visible {
    outline-offset: -2px;
  }
`;

export const SearchResultIcon = styled(Icon)`
  padding: 0.6rem;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfiniteScrollTrigger = styled.div`
  height: 44px;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.light[0]};
`;

export const ResultsRetractorButton = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.light[0]};
  border: none;
`;
