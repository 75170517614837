import { mapsUrl } from '@/settings';

export const mapConfig = {
  center: [19.453383223199438, 51.750138932202276],
  xyz: mapsUrl,
  xyzEnd: '{z}/{x}/{y}.png',
  zoom: 16,
  minZoom: 13,
  maxZoom: 28,
  constrainResolution: false,
  defaultProfile: '0',
  featureProjection: 'EPSG:3857',
};

export const mapStyles = {
  primaryColor: '#192647',
  selectFillColor: '#3983FF80',
  selectStrokeColor: '#3983FF',
  selectStrokeWidthObject: 4,
  selectStrokeWidthIcon: 8,
  textBackground: '#FFFFFF',
};
