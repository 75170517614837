import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';

import { themeDefault } from '@/settings/themes';
import { GlobalStyles } from '@/settings/globalStyles';

import { Map } from '@/pages/Map';
import { Toaster } from 'react-hot-toast';
import Modal from 'react-modal';

import '@/settings/i18n/i18n';
import { checkIsIOS, addMaximumScaleToMetaViewport } from './utils';

const queryClient = new QueryClient();

Modal.setAppElement('#root');

export const App = (): JSX.Element => {
  React.useEffect(() => {
    if (checkIsIOS()) {
      addMaximumScaleToMetaViewport();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={themeDefault}>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            style: {
              fontSize: 14,
            }
          }}
        />
        <Map />
        <GlobalStyles />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
};
