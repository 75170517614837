import { ChangeEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@/ui/Icon';
import { SrOnly } from '@/ui/SrOnly';
import { Text } from '@/ui/Text';
import { SearchResults } from './SearchResults';

import { useSearchResultsQuery } from '../../queries';
import { useDebounce } from '@/hooks/useDebounce';
import { useMapDispatchContext, useMapStateContext } from '../../Context/useMapContext';
import { useRouteDispatch, useRouteState } from '../../Context/useRoute';

import logoIcon from '@/assets/icons/logo.svg';
import routeIcon from '@/assets/icons/route.svg';
import searchIcon from '@/assets/icons/search.svg';
import closeIcon from '@/assets/icons/close.svg';
import {
  ActionButton,
  SearchClear,
  SearchContainer,
  SearchContainerItem,
  SearchInput,
  SearchInputContainer,
  SearchInputIcon,
} from './styles';

export const Search = (): JSX.Element => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const refInput = useRef<HTMLInputElement>(null);
  const { data, nextListPage, isLoading, isFetchingNextPage, hasNextPage } = useSearchResultsQuery(debouncedSearch);
  const { selectedObject, selectedCustomMapObject } = useMapStateContext();
  const { handleSelectObject } = useMapDispatchContext();
  const {setOpenRoutePanel} = useRouteDispatch();
  const {openRoutePanel} = useRouteState();

  const handleCloseAsideInformation = () => {
    handleSelectObject(null, 'search');
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value);
    handleCloseAsideInformation();
  };

  const handleClear = () => {
    setSearch('');
  };

  const handleChooseElement = (id: number) => {
    handleSelectObject(id, 'search');
  };

  return (
    <SearchContainer>
      <SearchContainerItem>
        <Icon src={logoIcon} size={[74, 26]} />
        <SearchInputContainer onClick={() => refInput.current?.focus()}>
          <SearchInputIcon src={searchIcon} size={[20, 20]} />
          <SrOnly>
            <label htmlFor='searchInput'>{t('map.controls.searchPlaceholder')}</label>
          </SrOnly>
          <SearchInput
            ref={refInput}
            id='searchInput'
            type='text'
            value={search}
            onChange={handleChange}
            placeholder={t('map.controls.searchPlaceholder')}
          />
          {search && (
            <SearchClear onClick={handleClear}>
              <Text srOnly={true}>{t('labels.clearSearch')}</Text>
              <Icon src={closeIcon} size={[16, 16]} />
            </SearchClear>
          )}
        </SearchInputContainer>
        <ActionButton closeMode={!!selectedObject || !!selectedCustomMapObject} onClick={(selectedObject || selectedCustomMapObject) ? handleCloseAsideInformation : () => setOpenRoutePanel(true)}>
          <Text srOnly={true}>{(selectedObject || selectedCustomMapObject) ? t('labels.closeObjectInformation') : t('labels.planRoute')}</Text>
          {(selectedObject || selectedCustomMapObject) ? <Icon src={closeIcon} size={[32, 32]} /> : <Icon src={routeIcon} size={[24, 24]} />}
        </ActionButton>
      </SearchContainerItem>
      {search && !selectedObject && !selectedCustomMapObject && !openRoutePanel && (
        <SearchResults
          searchResults={data}
          isLoading={isLoading || isFetchingNextPage}
          hasNextPage={hasNextPage}
          search={search}
          nextListPage={nextListPage}
          handleChooseElement={handleChooseElement}
          handleClear={handleClear}
        />
      )}
    </SearchContainer>
  );
};
