import React from 'react';
import { useInView } from 'react-intersection-observer';
import isEmpty from 'lodash.isempty';

import type { InfiniteData } from '@tanstack/react-query';
import type { ISearchResult } from '../../types';

import { Text } from '@/ui/Text';
import { SearchResultLoader } from '@/ui/Loading';

import { Empty } from '@/ui/Empty';

import { InfiniteScrollTrigger, Info, SearchResult, SearchResultIcon, SearchResultsContainer, SearchResultsList } from './styles';

interface RoutingSearchResultsProps {
  searchResults: InfiniteData<ISearchResult> | undefined;
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  nextListPage: () => void;
  handleChooseElement: (id: number) => void;
}

export const RoutingSearchResults = ({
  searchResults,
  isLoading,
  hasNextPage,
  nextListPage,
  handleChooseElement,
}: RoutingSearchResultsProps): JSX.Element => {
  const { ref, inView } = useInView({});

  React.useEffect(() => {
    if (inView) {
      nextListPage();
    }
  }, [inView, nextListPage]);

  return (
    <SearchResultsContainer forceVisible={true}>
      <SearchResultsList>
        {searchResults?.pages.map(searchResult => (
          <li key={searchResult.id}>
            <SearchResult
              onClick={() => handleChooseElement(searchResult.id)}
              onKeyDown={e => (e.key === 'Enter' ? handleChooseElement(searchResult.id) : undefined)}
              tabIndex={0}
            >
              <SearchResultIcon
                src={
                  searchResult.properties.category.pathPrimary
                    ? searchResult.properties.category.pathPrimary
                    : searchResult.properties.category.path
                }
                size={[32, 32]}
              />
              <Info>
                <h3>
                  <Text weight={400} size={14} palette={{ primary: 100 }}>
                    {searchResult.properties.name}
                  </Text>
                </h3>
                <h4>
                  <Text weight={400} size={14} palette={{ light: 80 }}>
                    {searchResult.properties.subname}
                  </Text>
                </h4>
              </Info>
            </SearchResult>
          </li>
        ))}
        {isLoading ? <SearchResultLoader /> : hasNextPage && <InfiniteScrollTrigger ref={ref} />}
      </SearchResultsList>
      {searchResults && isEmpty(searchResults.pages) && <Empty />}
    </SearchResultsContainer>
  );
};
