import { CSSProperties } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Styles } from '@/settings/themes/themeInterface';

export type ButtonVariants = 'DEFAULT' | 'ACTIVE' | 'BADGE';

type ButtonStyles = Required<Pick<CSSProperties, 'backgroundColor'>>;

const styles = (theme: DefaultTheme): Styles<ButtonVariants, ButtonStyles> => ({
  DEFAULT: {
    backgroundColor: theme.palette.primary['100'],
  },
  ACTIVE: {
    backgroundColor: theme.palette.light['400'],
  },
  BADGE: {
    backgroundColor: theme.palette.primary['100'],
  },
});

export const ShadowContainer = styled.button<{ variant: ButtonVariants }>`
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, variant }) => styles(theme)[variant].backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius['10']};
  border: none;
  cursor: pointer;
`;

export const Badge = styled.span`
  position: absolute;
  right: -4px;
  bottom: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.secondary['100']};
  border-radius: 24px;
`;
