import type { DefaultTheme } from 'styled-components';

export const themeDefault: DefaultTheme = {
  palette: {
    primary: {
      100: '#192647',
      200: '#172548',
    },
    secondary: {
      100: '#D80048',
      200: '#f88aa2',
    },
    tertiary: {
      50: '#ACCBFF',
      100: '#3983FF',
    },
    background: {
      100: '#F0F0F0',
    },
    opacity: {
      25: 'rgba(18, 34, 75, 0.03)',
      50: 'rgba(27, 37, 73, 0.12)',
      75: 'rgba(23, 37, 72, 0.04)',
      100: 'rgba(148, 148, 148, 0.19)',
      150: 'rgba(0, 0, 0, 0.1)',
      200: 'rgba(0, 0, 0, 0.7)',
    },
    shadow: {
      100: '#1B2549',
    },
    light: {
      0: '#FFFFFF',
      50: '#F8F8F8',
      60: '#E8E8E8',
      75: '#DADADA',
      80: '#D2D2D2',
      90: '#B4B4B4',
      100: '#C2D1D9',
      200: '#939CB2',
      300: '#8995A9',
      400: '#5B6684',
      450: '#505D68',
      500: '#424B5A',
      600: '#767676',
      1000: '#000000',
    },
    info: {
      50: '#D5DFE7',
      100: '#3F9CE0',
    },
    success: {
      50: '#CCEEDA',
      100: '#0A612D',
    },
    warning: {
      50: '#FFF8E7',
      100: '#FFBA08',
    },
    error: {
      50: '#FCEBEB',
      100: '#DB0000',
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: {
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900,
    },
    size: {
      8: '0.8rem',
      9: '0.9rem',
      10: '1rem',
      11: '1.1rem',
      12: '1.2rem',
      13: '1.3rem',
      14: '1.4rem',
      15: '1.5rem',
      16: '1.6rem',
      17: '1.7rem',
      18: '1.8rem',
      19: '1.9rem',
      20: '2rem',
    },
  },
  borderRadius: {
    8: '8px',
    10: '10px',
    12: '12px',
    14: '14px',
    20: '20px',
  },
};
