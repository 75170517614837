import styled from 'styled-components';
import { breakpoints } from '@/settings/globalStyles/breakpoints';

export const ZoomContainer = styled.div`
  z-index: 10;
  position: absolute;
  right: 185px;
  bottom: 45px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  background-color: rgba(255,255,255, 0.4);

  @media (max-width: ${breakpoints.md}) {
    right: 175px;
    bottom: 15px;
  }
`;

export const ZoomButton = styled.button`
  width: 35px;
  height: 35px;
  background-color: ${({ theme }) => theme.palette.primary[100]};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  border: none;
  cursor: pointer;
`;

export const ZoomInfo = styled.p`
  text-align: center;
  width: 4.2rem;
  cursor: default;
  user-select: none;
`;
