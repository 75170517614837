import { useTranslation } from 'react-i18next';

import { useMapDispatchContext, useMapStateContext } from '../../Context/useMapContext';

import { Icon } from '@/ui/Icon';
import { Text } from '@/ui/Text';

import FullscreenOpenIcon from '@/assets/icons/fullscreenOpen.svg';
import FullscreenCloseIcon from '@/assets/icons/fullscreenClose.svg';
import { FullscreenButton } from './styles';

export const Fullscreen = (): JSX.Element => {
  const { t } = useTranslation();
  const { fullscreenMode } = useMapStateContext();
  const { handleFullscreen } = useMapDispatchContext();

  return (
    <FullscreenButton onClick={handleFullscreen}>
      {fullscreenMode ? <Icon src={FullscreenCloseIcon} size={[16, 16]} /> : <Icon src={FullscreenOpenIcon} size={[16, 16]} />}{' '}
      <Text weight={300} size={14} palette={{ light: 0 }}>
        {t('map.controls.fullscreen')}
      </Text>
    </FullscreenButton>
  );
};
