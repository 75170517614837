import React from 'react';
import { LanguageSwitchContainer, LanguageButton } from './styles';
import { Language } from '@/settings/i18n/lang';
import { Text } from '@/ui/Text';
import { useTranslation } from 'react-i18next';

export const LanguageSwitch = React.memo(() => {
  const {i18n, t} = useTranslation();

  return (
    <LanguageSwitchContainer>
      {
        Object.values(Language).map((language) => {
          const isActive = i18n.resolvedLanguage === language;

          return (
            <li key={language}>
              <LanguageButton active={isActive} onClick={() => i18n.changeLanguage(language)}>
                <Text srOnly={true}>{`${t('labels.changeLanguage')} `}</Text>
                <Text weight={500} size={14} palette={isActive ? {primary: 100} : {light: 0}} transform="uppercase">
                  {language}
                </Text>
              </LanguageButton>
            </li>
          )
        })
      }
    </LanguageSwitchContainer>
  )
});
